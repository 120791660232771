/**
 * Busca pela informação de cliente/medico na localStorage
 * @returns string - 'cliente' || 'medico'
 */
const getClientMedic = () => typeof window !== 'undefined' && localStorage.getItem('context')

const setClientMedic = identifierString =>
	typeof window !== 'undefined' ? localStorage.setItem('context', identifierString) : null

/**
 * Facilitador da função de cliente/medico, verifica na localStorage o valor e retorna um booleano informando se é cliente
 * @returns boolean
 */
const isClientPersisted = () => typeof window !== 'undefined' && localStorage.getItem('context') !== 'medico' // se for undefined, ele deve voltar true, por isso o !== 'medico'

export { getClientMedic, setClientMedic, isClientPersisted }
