import styled from 'styled-components'

export default styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: 40px;
	grid-auto-rows: auto;
	grid-template-areas:
		'l l l l l l l l r r r r'
		'c c c c c c c c c c c c';
	.left {
		grid-area: l;
	}
	.right {
		grid-area: r;
	}
	.cards {
		grid-area: c;
	}

	@media (max-width: 768px) {
		grid-template-areas:
			'l l l l l l l l l l l l'
			'c c c c c c c c c c c c'
			'r r r r r r r r r r r r';
		// display: block;
		padding-bottom: 20px;
		grid-gap: 0;
	}
`
