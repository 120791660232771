import styled from 'styled-components'

export default styled.div`
	max-width: 1360px;
	padding: 0;
	margin: 0 auto;
	padding: 20px;

	@media (max-width: 375px) {
		padding: 0 10px;
	}
`
