import React from 'react'

const SvgIconRevistas = ({ isClient, theme, ...props }) => (
	<svg
		version='1.1'
		id='Capa_1'
		xmlns='http://www.w3.org/2000/svg'
		x='0px'
		y='0px'
		viewBox='0 0 511.999 511.999'
		enable-background='new 0 0 511.999 511.999;'
	>
		<g>
			<g>
				<path
					fill={!isClient ? theme.colors.white : theme.colors.primary}
					d='M510.156,401.843L480.419,315.3c14.334-29.302,21.909-61.89,21.96-94.679c0.088-57.013-21.97-110.92-62.112-151.79
			C400.117,27.953,346.615,4.942,289.615,4.039C230.51,3.105,174.954,25.587,133.187,67.353
			c-40.274,40.273-62.612,93.366-63.319,150.102C30.174,247.341,6.745,293.936,6.822,343.705
			c0.037,23.29,5.279,46.441,15.212,67.376L1.551,470.689c-3.521,10.247-0.949,21.373,6.713,29.035
			c5.392,5.393,12.501,8.264,19.812,8.264c3.076,0,6.188-0.508,9.223-1.551l59.609-20.483c20.935,9.933,44.086,15.175,67.376,15.212
			c0.084,0,0.164,0,0.248,0c50.51-0.002,97.46-24.035,127.237-64.702c30.987-0.816,61.646-8.317,89.363-21.876l86.544,29.738
			c3.606,1.239,7.304,1.843,10.959,1.843c8.688,0,17.136-3.412,23.545-9.822C511.284,427.242,514.34,414.021,510.156,401.843z
			 M164.53,470.69c-0.065,0-0.134,0-0.199,0c-20.614-0.031-41.085-5.113-59.196-14.695c-3.724-1.969-8.096-2.31-12.078-0.942
			l-61.123,21.003l21.003-61.122c1.368-3.983,1.028-8.355-0.942-12.078c-9.582-18.112-14.664-38.582-14.696-59.197
			c-0.051-33.159,12.848-64.588,35.405-88.122c7.368,44.916,28.775,86.306,61.957,118.898
			c32.937,32.351,74.339,52.949,119.011,59.683C230.084,457.367,198.288,470.69,164.53,470.69z M480.628,414.797
			c-0.867,0.867-1.895,1.103-3.051,0.705l-92.648-31.836c-1.609-0.553-3.283-0.827-4.951-0.827c-2.459,0-4.909,0.595-7.126,1.769
			c-26.453,13.994-56.345,21.416-86.447,21.462c-0.099,0-0.189,0-0.288,0c-100.863,0-184.176-81.934-185.774-182.773
			c-0.805-50.785,18.513-98.514,54.394-134.395c35.881-35.881,83.618-55.192,134.396-54.392
			c100.936,1.601,182.926,85.068,182.77,186.063c-0.047,30.102-7.468,59.995-21.461,86.446c-1.97,3.723-2.31,8.095-0.942,12.078
			l31.835,92.648C481.732,412.905,481.494,413.932,480.628,414.797z'
				/>
			</g>
		</g>
		<g>
			<g>
				<path
					fill={!isClient ? theme.colors.white : theme.colors.primary}
					d='M376.892,139.512h-181.56c-8.416,0-15.238,6.823-15.238,15.238c0,8.416,6.823,15.238,15.238,15.238h181.56
			c8.416,0,15.238-6.823,15.238-15.238C392.13,146.335,385.308,139.512,376.892,139.512z'
				/>
			</g>
		</g>
		<g>
			<g>
				<path
					fill={!isClient ? theme.colors.white : theme.colors.primary}
					d='M376.892,202.183h-181.56c-8.416,0-15.238,6.823-15.238,15.238s6.823,15.238,15.238,15.238h181.56
			c8.416,0,15.238-6.823,15.238-15.238S385.308,202.183,376.892,202.183z'
				/>
			</g>
		</g>
		<g>
			<g>
				<path
					fill={!isClient ? theme.colors.white : theme.colors.primary}
					d='M307.004,264.852H195.331c-8.416,0-15.238,6.823-15.238,15.238c0,8.416,6.823,15.238,15.238,15.238h111.672
			c8.416,0,15.238-6.823,15.238-15.238C322.241,271.675,315.42,264.852,307.004,264.852z'
				/>
			</g>
		</g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
		<g></g>
	</svg>
)

export default SvgIconRevistas
