/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box } from '@material-ui/core'
//import { KeyboardArrowRight, Close, LocalPhone, WhatsApp } from '@material-ui/icons'
import { Close, LocalPhone, WhatsApp } from '@material-ui/icons'
import MenuOuvidoria from '../Icons/IconOuvidoria'
import Dialog from '@material-ui/core/Dialog'
import { isMobile } from 'react-device-detect'

const RoundedDialog = styled(Dialog)`
	.MuiDialog-paper {
		border-radius: 10px;
	}
`

const ImageContent = styled.div`
	cursor: pointer;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	margin-top: -4px;
`

const Title = styled.span`
	margin-top: -8px;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 24px;
	text-align: center;
	color: #009ddf !important;
	display: flex;
	justify-content: center;
`

const Text = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	text-align: flex-start;
	color: #444444;
	padding-top: 16px;
`

const AttendanceHours = styled.span`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	text-align: flex-start;
	background-color: #e5f3f1;
	border-radius: 6px;
	color: #444444;
	padding: 12px;

	ul {
		padding-left: 24px !important;
		margin-top: 4px !important;
		margin-bottom: -2px !important;
	}
`

const Separator = styled.div`
	padding-top: 12px;
`

const ActionButton = styled.div`
	cursor: pointer;
	display: flex;
	text-transform: initial !important;
	color: #009ddf !important;
	align-items: center !important;
	justify-content: center !important;
	height: 40px !important;
	font-family: 'fs_lolaregular' !important;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 16px !important;
	line-height: 16px !important;
`

export const brandLinks = [
	{
		brand: 'amaissp',
		// additionalText: (
		// 	<>
		// 		Agende ou cancele exames, consulte orçamentos e instruções de preparo no
		// 		<b>&nbsp;Agendamento Digital</b>
		// 	</>
		// ),
		// additionalAction: (
		// 	<>
		// 		Abrir site &nbsp;
		// 		<KeyboardArrowRight fontSize='large' />
		// 	</>
		// ),
		// additionalLink: 'https://agendamento.amaissaude.com.br/entrar',
		text1: 'Consulte informações gerais, notas fiscais e mais:',
		action1: (
			<>
				<LocalPhone />
				&nbsp;&nbsp; 3139-8000
			</>
		),
		link1: 'tel:31398000',
		action2: (
			<>
				<WhatsApp />
				&nbsp;&nbsp; Abrir WhatsApp
			</>
		),
		link2: 'https://api.whatsapp.com/send?phone=551131398000',
		text2: 'Tem sugestões, elogios ou reclamações?',
		attendanceHours: (
			<>
				Horário de atendimento:
				<ul>
					<li>
						Segunda a sexta, <b>&nbsp;das 7h às 20h</b>
					</li>
					<li>
						Sábados e feriados, <b>&nbsp;das 7h às 14h</b>
					</li>
				</ul>
			</>
		),
		action3: (
			<>
				<MenuOuvidoria />
				&nbsp;&nbsp; Ouvidoria
			</>
		),
		link3: 'https://maisservicos.amaissaude.com.br/ouvidoria?regional=sp'
	},
	{
		brand: 'amaispe',
		text1: 'Agende, reagende ou cancele exames, consulte orçamentos, notas fiscais e outras informações:',
		action1: (
			<>
				<LocalPhone />
				&nbsp;&nbsp; 4004-3050
			</>
		),
		link1: 'tel:40043050',
		action2: (
			<>
				<WhatsApp />
				&nbsp;&nbsp; Abrir WhatsApp
			</>
		),
		link2: 'https://api.whatsapp.com/send?phone=558140043050',
		text2: 'Tem sugestões, elogios ou reclamações?',
		attendanceHours: (
			<>
				Horário de atendimento:
				<ul>
					<li>
						Segunda a sexta, <b>&nbsp;das 7h às 20h</b>
					</li>
					<li>
						Sábados e feriados, <b>&nbsp;das 7h às 14h</b>
					</li>
				</ul>
			</>
		),
		action3: (
			<>
				<MenuOuvidoria />
				&nbsp;&nbsp; Ouvidoria
			</>
		),
		link3: 'https://maisservicos.amaissaude.com.br/ouvidoria?regional=pe'
	},
	{
		brand: 'amaispr',
		text1: 'Agende, reagende ou cancele exames, consulte orçamentos, notas fiscais e outras informações:',
		action1: (
			<>
				<LocalPhone />
				&nbsp;&nbsp; 4020-8000
			</>
		),
		link1: 'tel:40208000',
		action2: (
			<>
				<WhatsApp />
				&nbsp;&nbsp; Abrir WhatsApp
			</>
		),
		link2: 'https://api.whatsapp.com/send?phone=554140208000',
		text2: 'Tem sugestões, elogios ou reclamações?',
		attendanceHours: (
			<>
				Horário de atendimento:
				<ul>
					<li>
						Segunda a sexta, <b>&nbsp;das 6h30 às 20h</b>
					</li>
					<li>
						Sábados e feriados, <b>&nbsp;das 6h30 às 14h</b>
					</li>
				</ul>
			</>
		),
		action3: (
			<>
				<MenuOuvidoria />
				&nbsp;&nbsp; Ouvidoria
			</>
		),
		link3: 'https://maisservicos.amaissaude.com.br/ouvidoria?regional=pr'
	},
	{
		brand: 'amaispi',
		text1: 'Agende, reagende ou cancele exames, consulte orçamentos, notas fiscais e outras informações:',
		action1: (
			<>
				<LocalPhone />
				&nbsp;&nbsp; 4004-3050
			</>
		),
		link1: 'tel:40043050',
		action2: (
			<>
				<WhatsApp />
				&nbsp;&nbsp; Abrir WhatsApp
			</>
		),
		link2: 'https://api.whatsapp.com/send?phone=5586994798419',
		text2: 'Tem sugestões, elogios ou reclamações?',
		attendanceHours: (
			<>
				Horário de atendimento:
				<ul>
					<li>
						Segunda a sexta, <b>&nbsp;das 7h às 20h</b>
					</li>
					<li>
						Sábados e feriados, <b>&nbsp;das 7h às 14h</b>
					</li>
				</ul>
			</>
		),
		action3: (
			<>
				<MenuOuvidoria />
				&nbsp;&nbsp; Ouvidoria
			</>
		),
		link3: 'https://maisservicos.amaissaude.com.br/ouvidoria?regional=pi'
	},
	{
		brand: 'irn',
		text1: 'Agende, reagende ou cancele exames, consulte orçamentos, notas fiscais e outras informações:',
		action1: (
			<>
				<LocalPhone />
				&nbsp;&nbsp; 4004-3220
			</>
		),
		link1: 'tel:40043220',
		action2: (
			<>
				<WhatsApp />
				&nbsp;&nbsp; Abrir WhatsApp
			</>
		),
		link2: 'https://api.whatsapp.com/send?phone=558440043220',
		text2: 'Tem sugestões, elogios ou reclamações?',
		attendanceHours: (
			<>
				Horário de atendimento:
				<ul>
					<li>
						Segunda a sexta, <b>&nbsp;das 7h às 20h</b>
					</li>
					<li>
						Sábados e feriados, <b>&nbsp;das 7h às 14h</b>
					</li>
				</ul>
			</>
		),
		action3: (
			<>
				<MenuOuvidoria />
				&nbsp;&nbsp; Ouvidoria
			</>
		),
		link3: 'https://maisservicos.institutoderadiologia.com.br/ouvidoria'
	},
	{
		brand: 'diagnoson',
		text1: 'Agende, reagende ou cancele exames, consulte orçamentos, notas fiscais e outras informações:',
		action1: (
			<>
				<LocalPhone />
				&nbsp;&nbsp; 4004-3010
			</>
		),
		link1: 'tel:40043010',
		action2: (
			<>
				<WhatsApp />
				&nbsp;&nbsp; Abrir WhatsApp
			</>
		),
		link2: 'https://api.whatsapp.com/send?phone=557140043010',
		text2: 'Tem sugestões, elogios ou reclamações?',
		attendanceHours: (
			<>
				Horário de atendimento:
				<ul>
					<li>
						Segunda a sexta, <b>&nbsp;das 7h às 20h</b>
					</li>
					<li>
						Sábados e feriados, <b>&nbsp;das 7h às 14h</b>
					</li>
				</ul>
			</>
		),
		action3: (
			<>
				<MenuOuvidoria />
				&nbsp;&nbsp; Ouvidoria
			</>
		),
		link3: 'https://maisservicos.diagnosonamais.com.br/ouvidoria'
	},
	{
		brand: 'labs-amais',
		text1: 'Agende, reagende ou cancele exames, consulte orçamentos, notas fiscais e outras informações:',
		action1: (
			<>
				<LocalPhone />
				&nbsp;&nbsp; (21) 2538-3600
			</>
		),
		link1: '',
		action2: (
			<>
				<WhatsApp />
				&nbsp;&nbsp; Abrir WhatsApp
			</>
		),
		link2: 'https://api.whatsapp.com/send?phone=552125383600',
		text2: 'Tem sugestões, elogios ou reclamações?',
		attendanceHours: (
			<>
				Horário de atendimento:
				<ul>
					<li>
						Segunda a sexta, <b>&nbsp;das 7h às 21h</b>
					</li>
					<li>
						Sábados e feriados, <b>&nbsp;das 7h às 17h</b>
					</li>
				</ul>
			</>
		),
		action3: (
			<>
				<MenuOuvidoria />
				&nbsp;&nbsp; Ouvidoria
			</>
		),
		link3: 'https://maisservicos.labsamais.com.br/ouvidoria'
	}
]

function MoldalFaleConosco(props) {
	const { onClose, open } = props

	const handleClose = () => {
		onClose()
	}

	const [optionSelected, setOptionSelected] = useState()

	const brandOptions = brandLinks.find(x => x.brand === process.env.GATSBY_COSMIC_BUCKET)

	const options = [
		{
			title: 'FALE CONOSCO',
			additionalText: brandOptions?.additionalText,
			additionalAction: brandOptions?.additionalAction,
			additionalLink: brandOptions?.additionalLink,
			text1: brandOptions.text1,
			action1Text: brandOptions?.action1,
			action1Link: brandOptions?.link1,
			action2Text: brandOptions?.action2,
			action2Link: brandOptions?.link2,
			attendanceHours: brandOptions?.attendanceHours,
			text2: brandOptions.text2,
			action3Text: brandOptions?.action3,
			action3Link: brandOptions?.link3
		}
	]

	useEffect(() => {
		setOptionSelected(options[0])
	}, [])

	return (
		<RoundedDialog onClose={handleClose} aria-labelledby='contactUs-modal' open={open}>
			<Box
				display='flex'
				gap={2}
				flexDirection='column'
				flexWrap='wrap'
				width={isMobile ? '82%' : 'fit-content'}
				maxWidth={480}
				alignItems='flex-start'
				padding={3}
			>
				<ImageContent
					onClick={() => {
						handleClose()
					}}
				>
					<Close fontSize='small' />
				</ImageContent>
				<Title>{optionSelected?.title}</Title>
				{optionSelected?.additionalLink?.length > 0 && (
					<>
						<Separator />
						<Text>{optionSelected?.additionalText}</Text>
						<ActionButton onClick={() => window.open(optionSelected?.additionalLink, '_self')}>
							{optionSelected?.additionalAction}
						</ActionButton>
					</>
				)}
				<Separator />
				<Text minWidth={isMobile ? '180px' : '450px'}>{optionSelected?.text1}</Text>
				<Separator />
				<ActionButton
					onClick={() => optionSelected?.action1Link.length > 0 && window.open(optionSelected?.action1Link, '_blank')}
				>
					{optionSelected?.action1Text}
				</ActionButton>
				<ActionButton
					onClick={() => optionSelected?.action2Link.length > 0 && window.open(optionSelected?.action2Link, '_blank')}
				>
					{optionSelected?.action2Text}
				</ActionButton>
				<Separator />
				<AttendanceHours>{optionSelected?.attendanceHours}</AttendanceHours>
				<Text>{optionSelected?.text2}</Text>
				<ActionButton
					onClick={() => {
						if (
							optionSelected?.action3Link.substring(0, 20) === 'https://api.whatsapp' ||
							optionSelected?.action3Link.substring(0, 4) === 'tel:'
						) {
							window.open(optionSelected?.action3Link, '_blank')
						} else {
							window.location.href = optionSelected?.action3Link
						}
					}}
				>
					{optionSelected?.action3Text}
				</ActionButton>
				<Separator />
			</Box>
		</RoundedDialog>
	)
}

export default MoldalFaleConosco
