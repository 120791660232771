import { useEffect, useState } from 'react'

const useCreateElement = ({ where = 'head', type = 'script', children, insertOnTop, ...props }) => {
	let element = null
	const [options, setOptions] = useState(null)

	useEffect(() => {
		if (!element) {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			element = document.createElement(type)
			if (insertOnTop) document[where].insertBefore(element, document[where].childNodes[0])
			else document[where].appendChild(element)
		}
		// else {
		// 	element = el.cloneNode(true)

		// }

		if (props.attributes) {
			// eslint-disable-next-line array-callback-return
			props.attributes.map(att => {
				element.setAttribute(att.name, att.value)
			})
		}

		if (children) element.innerHTML = children.toString().trim()
		let opts = { ...props, ...options }
		if (opts) {
			delete opts.attributes
			// se reatribuisse usasse um spread, o objeto html se transformaria em objeto convencional
			Object.keys(opts).forEach(key => {
				element[key] = opts[key]
			})
		}

		// el = element
		return () => {
			document[where].removeChild(element)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [where, type, children, insertOnTop, props, options])

	return [element, setOptions]
}

export default useCreateElement
