import React from 'react'

const SvgIconOuvidoria = ({ isClient, theme, ...props }) => (
	<svg width='21' height='22' viewBox='0 -2 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M18 0H2C0.9 0 0 0.9 0 2V20L4 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 14H3.2L2 15.2V2H18V14Z'
			fill='#009ddf'
		/>
	</svg>
)

export default SvgIconOuvidoria
