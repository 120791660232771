import React from 'react'
import styled, { keyframes } from 'styled-components'
import { theme } from 'styled-tools'

const Overlay = styled.div`
	display: ${props => (props.show ? 'block' : 'none')};
	position: fixed;
	z-index: 10;
	padding-top: 4%;
	left: 0;
	/* top: 120px; */
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.4);
	@media (min-width: 1024px) {
		// padding-top: 170px;
	}
`

const animatetop = keyframes`
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
`

const Modal = styled.div`
	// max-height: 75%;
	// overflow-y: auto;
	position: relative;
	background: ${theme('colors.white')};
	margin: auto;
	padding: 0;
	border-top: 10px solid ${props => props.theme.colors.primary};
	border-radius: 5px;
	width: calc(100% - 30px);
	max-width: 550px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	animation: ${animatetop} 0.5s linear;
	padding-top: 40px;
	.content {
		text-align: center;
	}
	.infoText {
		text-align: justify;
		margin: 30px auto 20px;
		max-width: 292px;
		font-size: 12px;
		line-height: 16px;
		color: ${props => props.theme.colors.gray66};
	}

	@media (max-width: 768px) {
		max-width: 400px;
	}
`
const ModalSp = styled.div`
	position: relative;
	background: ${theme('colors.white')};
	margin: auto;
	border-radius: 19px;
	width: 75%;
	height: auto;
	max-width: 350px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	animation: ${animatetop} 0.5s linear;
	.content {
		text-align: center;
	}
	.infoText {
		text-align: justify;
		margin: 30px auto 20px;
		max-width: 292px;
		font-size: 12px;
		line-height: 16px;
		color: ${props => props.theme.colors.gray66};
	}
	padding: 40px 20px 20px;

	@media (max-width: 375px) {
		width: 68%;
	}

	@media (max-width: 320px) {
		width: 80%;
	}
`

// const ModalGeneric = styled.div`
// 	// max-height: 75%;
// 	// overflow-y: auto;
// 	position: relative;
// 	background: ${theme('colors.white')};
// 	margin: auto;
// 	margin-top: 8%;
// 	padding: 0;
// 	//border-top: 10px solid ${props => props.theme.colors.primary};
// 	border-radius: 5px;
// 	width: calc(100% - 30px);
// 	max-width: 320px;
// 	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
// 	animation: ${animatetop} 0.5s linear;
// 	padding-top: 40px;
// 	.content {
// 		text-align: left;
// 	}
// 	.infoText {
// 		text-align: left;
// 		margin: 30px auto 20px;
// 		max-width: 292px;
// 		font-size: 12px;
// 		line-height: 16px;
// 		color: ${props => props.theme.colors.gray66};
// 	}

// 	@media (max-width: 768px) {
// 		max-width: 300px;
// 	}
// `

const scheduleGenericBrands = ['amaispe', 'amaispi', 'amaispr', 'diagnoson', 'irn', 'labs-amais']

const ShowModal = ({ children, show = true }) =>
	process.env.GATSBY_COSMIC_BUCKET === 'amaissp' ? (
		<>
			<Overlay className='overlay-modal' show={show}>
				<ModalSp>
					<div className='content'>{children}</div>
				</ModalSp>
			</Overlay>
		</>
	) : scheduleGenericBrands.includes(process.env.GATSBY_COSMIC_BUCKET) ? (
		<>
			<Overlay className='overlay-modal' show={show}>
				<ModalSp>
					<div className='content'>{children}</div>
				</ModalSp>
				{/* <ModalGeneric>
					<div className='content'>{children}</div>
				</ModalGeneric> */}
			</Overlay>
		</>
	) : (
		<>
			<Overlay className='overlay-modal' show={show}>
				<Modal>
					<div className='content'>{children}</div>
				</Modal>
			</Overlay>
		</>
	)

export default ShowModal
