import React from 'react'

const SvgMenuPin = ({ isClient, theme, ...props }) => (
	<svg width={23.185} height={28.527} {...props}>
		<defs>
			<clipPath id='menuPin_svg__clip-path'>
				<path
					id='menuPin_svg__Caminho_91'
					d='M377.4 126.307c-2.165-1.615-9.424-7.567-9.424-14.66a9.422 9.422 0 0118.842 0c.001 7.212-7.252 13.074-9.418 14.66zm-.006-26.131a11.545 11.545 0 00-11.59 11.471c0 9.424 10.536 16.571 10.98 16.872a1.1 1.1 0 001.207.006c.45-.29 11-7.283 11-16.878a11.547 11.547 0 00-11.596-11.471z'
					className='menuPin_svg_comp__cls-1'
					data-name='Caminho 91'
					transform='translate(-365.805 -100.176)'
				/>
			</clipPath>
			<clipPath id='menuPin_svg__clip-path-2'>
				<path
					id='menuPin_svg__Ret\xE2ngulo_371'
					d='M0 0h1713.867v95.215H0z'
					className='menuPin_svg_comp__cls-1'
					data-name='Ret\xE2ngulo 371'
				/>
			</clipPath>
			<style>{`.menuPin_svg_comp__cls-1{fill:${
				theme ? (!isClient ? theme.colors.white : theme.colors.primary) : ''
			}}`}</style>
		</defs>
		<g id='menuPin_svg__Grupo_747' data-name='Grupo 747'>
			<g id='menuPin_svg__Grupo_659' data-name='Grupo 659'>
				<g id='menuPin_svg__Grupo_658' data-name='Grupo 658' clipPath='url(#menuPin_svg__clip-path)'>
					<g id='menuPin_svg__Grupo_657' data-name='Grupo 657' transform='translate(-748.625 -17.855)'>
						<g id='menuPin_svg__Grupo_656' data-name='Grupo 656' clipPath='url(#menuPin_svg__clip-path-2)'>
							<path
								id='menuPin_svg__Ret\xE2ngulo_370'
								d='M0 0h35.083v40.432H0z'
								className='menuPin_svg_comp__cls-1'
								data-name='Ret\xE2ngulo 370'
								transform='translate(742.676 11.901)'
							/>
						</g>
					</g>
				</g>
			</g>
			<path
				id='menuPin_svg__Caminho_130'
				d='M11.154 14.282a3.57 3.57 0 10-3.567-3.573 3.574 3.574 0 003.567 3.573z'
				data-name='Caminho 130'
				fill='none'
				stroke={theme ? (!isClient ? theme.colors.white : theme.colors.primary) : ''}
				strokeMiterlimit={10}
				strokeWidth={2}
			/>
		</g>
	</svg>
)

export default SvgMenuPin
