import React from 'react'

const SvgResultadoExames = ({ isClient, theme, ...props }) => (
	<svg width={23.724} height={28.534} {...props}>
		<defs>
			<style>{`.resultadoExames_svg_comp__cls-1{fill:none;stroke:${
				theme ? (!isClient ? theme.colors.white : theme.colors.primary) : ''
			};stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}`}</style>
		</defs>
		<g
			id='prefix__Icone_Resultado_de_Exames'
			data-name='Icone_Resultado de Exames'
			transform='translate(-4.138 -1.505)'
		>
			<path
				id='prefix__Caminho_4921'
				d='M20.553 4.776h5.355a.954.954 0 01.954.954v22.355a.954.954 0 01-.954.954H6.092a.954.954 0 01-.954-.954V5.73a.954.954 0 01.954-.954h4.714'
				className='resultadoExames_svg_comp__cls-1'
				data-name='Caminho 4921'
			/>
			<rect
				id='prefix__Ret\xE2ngulo_1951'
				width={8.621}
				height={3.931}
				className='resultadoExames_svg_comp__cls-1'
				data-name='Ret\xE2ngulo 1951'
				rx={1.868}
				transform='translate(11.689 2.505)'
			/>
			<path
				id='prefix__Linha_57'
				d='M0 0h8.913'
				className='resultadoExames_svg_comp__cls-1'
				data-name='Linha 57'
				transform='translate(11.544 10.703)'
			/>
			<path
				id='prefix__Linha_58'
				d='M0 0h8.913'
				className='resultadoExames_svg_comp__cls-1'
				data-name='Linha 58'
				transform='translate(11.544 20.606)'
			/>
			<path
				id='prefix__Linha_59'
				d='M0 0h8.913'
				className='resultadoExames_svg_comp__cls-1'
				data-name='Linha 59'
				transform='translate(11.544 24.917)'
			/>
			<path
				id='prefix__Linha_60'
				d='M0 0h8.913'
				className='resultadoExames_svg_comp__cls-1'
				data-name='Linha 60'
				transform='translate(11.544 15.597)'
			/>
		</g>
	</svg>
)

export default SvgResultadoExames
