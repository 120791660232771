import React from 'react'

import { ToolbarItem } from 'reakit/Toolbar'
import styled from 'styled-components'

import { theme } from 'styled-tools'
import { Link } from 'gatsby'

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 150px;
	margin-bottom: 5px;
	//margin: 0 3% !important;

	@media (max-width: 1250px) {
		margin: 0 0% !important;
		width: 150px;
		a {
			font-size: 14px;
		}
	}
`

const StyledButton = styled(Link)`
	outline-style: none;
	text-decoration: none;

	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: ${theme('colors.white')};
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 3;
	letter-spacing: normal;
	text-align: center;
	color: ${({ type, defaulttype }) => (type === defaulttype ? theme('colors.primary') : theme('colors.gray34'))};
	text-transform: uppercase;
	border-bottom: 1px solid ${theme('colors.gray34')};

	width: 6em;
	min-height: 2em;

	&.active {
		font-weight: 700;

		&::before {
			position: absolute;
			left: 50%;
			bottom: 3px;
			transform: translateX(-50%);
			display: block;
			border: 4px solid transparent;
			border-bottom-color: ${theme('colors.primary')};
			content: '';
		}

		&::after {
			position: absolute;
			bottom: -1px;
			left: 0;
			display: block;
			width: 100%;
			height: 4px;
			background-color: ${theme('colors.primary')};
			content: '';
		}
	}

	&:hover {
		color: ${theme('colors.primary')};
	}
`

// *** padrão antigo do site substituído em 05/2023 ***
// const Triangle = styled.div`
// 	display: ${({ type, defaulttype }) => (type === defaulttype ? 'block' : 'none')};
// 	content: '';
// 	position: absolute;
// 	left: 35%;
// 	top: 100%;
// 	width: 0;
// 	height: 0;
// 	border-left: 20px solid transparent;
// 	border-right: 20px solid transparent;
// 	border-bottom: 20px solid ${({ isClient }) => (isClient ? theme('colors.gray1') : theme('colors.gray7b'))};
// 	clear: both;
// `

/**
 * Botões de "Cliente" e "Médico" da Whitebar.
 * type padrão é 'cliente'.
 */
export default function ClientOrDoctor({ toolbar, type = 'cliente', toggleType, isClient }) {
	return (
		<Wrapper>
			<ToolbarItem
				{...toolbar}
				as={StyledButton}
				type={type}
				defaulttype='cliente'
				className={isClient ? 'active' : ''}
				onClick={() => (type === 'cliente' ? '' : toggleType())}
				to='/'
				id='button-client'
			>
				Cliente
				{/* <Triangle type={type} defaulttype='cliente' isClient={isClient} /> */}
			</ToolbarItem>
			<ToolbarItem
				{...toolbar}
				as={StyledButton}
				type={type}
				defaulttype='medico'
				className={!isClient ? 'active' : ''}
				onClick={() => (type === 'medico' ? '' : toggleType())}
				to='/medico/'
				id='button-medic'
			>
				Médico
				{/* <Triangle type={type} defaulttype='medico' /> */}
			</ToolbarItem>
		</Wrapper>
	)
}
