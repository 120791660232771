import styled from 'styled-components'

export default styled.div`
	padding: ${({ p }) => (p ? p : '0')};
	margin: ${({ m }) => (m ? m : '0')};
	background: ${({ bg }) => bg};

	@media (max-width: 991.98px) {
		margin: ${({ mMob }) => (mMob ? mMob : '0')};
	}
`
