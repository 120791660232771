import React from 'react'

const SvgComponent = ({ isClient, theme, ...props }) => (
	<svg id='prefix__Grupo_744' width={25} height={25} data-name='Grupo 744' {...props}>
		<defs>
			<style>{`.prefix__cls-2{fill:none;stroke:${
				theme ? (!isClient ? theme.colors.white : theme.colors.primary) : ''
			};stroke-width:2px;stroke-linecap:round}`}</style>
		</defs>
		<g
			id='prefix__Elipse_5'
			data-name='Elipse 5'
			stroke={theme ? (!isClient ? theme.colors.white : theme.colors.primary) : ''}
			strokeWidth={2}
			fill='none'
		>
			<circle cx={12.5} cy={12.5} r={12.5} stroke='none' />
			<circle cx={12.5} cy={12.5} r={11.5} />
		</g>
		<g id='prefix__Grupo_743' data-name='Grupo 743' transform='translate(8.79 8.79)'>
			<g id='prefix__Grupo_734' data-name='Grupo 734' transform='translate(0 3.878)'>
				<path id='prefix__Linha_6' d='M0 0h7.419' className='prefix__cls-2' data-name='Linha 6' />
			</g>
			<path
				id='prefix__Linha_7'
				d='M0 0v7.419'
				className='prefix__cls-2'
				data-name='Linha 7'
				transform='translate(3.628)'
			/>
		</g>
	</svg>
)

export default SvgComponent
