import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
	border-top: solid 4px ${props => props.theme.colors.primary};
	position: absolute;
	left: 0;
	right: 0;

	display: ${({ show }) => (show ? 'block' : 'none')};
	-webkit-animation: ${({ show }) => (show ? 'fadein 1s linear' : 'fadeout 5s linear')};
	animation: ${({ show }) => (show ? 'fadein 1s linear' : 'fadeout 5s linear')};
	padding: 0 20px 40px 20px;

	@media (min-width: 375px) {
		padding: 0 10px 40px 10px;
	}

	@keyframes fadein {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	@keyframes fadeout {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
`
/**
 * Componente de collapse
 * @param {boolean} show - cartao aberto ou nao
 * @param {function} setRef - para capturar a ref
 * @param {string} widthContainer - largura atual do container para calculo
 * @param {*} children - nodes dentro do collapse
 * @param {*} ...props - qualquer outra prop html para a div principal
 */
export default ({ show, children, setRef, widthContainer, ...props }) => (
	<Container widthContainer={widthContainer} ref={setRef} show={show} {...props}>
		{children}
	</Container>
)
