import styled from 'styled-components'
import { theme } from 'styled-tools'

export default styled.hr`
	border: none;
	background: ${theme('colors.graye2')};
	height: 1px;
	width: 100%;
	@media (max-width: 1024px) {
		display: none;
	}
`
