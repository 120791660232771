import React from 'react'

import styled from 'styled-components'
import { IoIosSearch } from 'react-icons/io'
import { Input } from '.'

const CompContainer = styled.div`
	@media (max-width: 991.98px) {
		width: 100%;
	}
`

const SidebarItem = styled.div`
	padding: 0 0 20px;
`

const SearchSpan = styled.span`
	// border: 1px solid ${props => props.theme.colors.graye2};
	// border-radius: 5px;
	padding: 5px 10px;
	// width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 767.98px) {
		border: ${props => (props.borderSearch ? 'solid 1px #e5e5e5' : 'none')};
		background: ${props => (props.backgroundSearch ? props.theme.colors.white : props.theme.colors.grayf1)};
		border-radius: unset;
		padding: ${props => (props.paddingSearch ? 'none' : '10px')} ;
		border-radius: 5px;
	}
`

const SearchButton = styled.button`
	background: none;
	border: none;
	padding: 0;
	margin-top: auto;
	z-index: 0;
	cursor: pointer;
`

const IoIosSearchIcon = styled(IoIosSearch)`
	color: ${props => (props.colorIcon ? '#8e8e93' : props.theme.colors.primary)};
	width: 20px;
	height: 20px;
`

/**
 * Componente de busca
 * @param {function} onSearch - callback para executar ao digitar
 * @param {function} onKeyPress - callback para executar ao digitar Insert
 * @param {icon} iconLeft - item a ser exibido à esqueda do input
 * @param {string} idButton - id para a lupa
 * @param {*} ...props - outras props html para o input
 */
export default ({
	onSearch,
	onKeyPress,
	iconLeft: IconLeft,
	colorIcon,
	backgroundSearch,
	borderSearch,
	paddingSearch,
	idButton,
	...props
}) => {
	const handlePressEnter = e => e.key === 'Enter' && onKeyPress && onKeyPress(e)
	return (
		<CompContainer>
			<SidebarItem>
				<SearchSpan backgroundSearch={backgroundSearch} borderSearch={borderSearch} paddingSearch={paddingSearch}>
					{IconLeft && <IconLeft />}
					<Input
						iconLeft={!!IconLeft}
						paddingInlineEnd='25px'
						inputBorderBottom='1px solid #ddd'
						onKeyPress={handlePressEnter}
						inSearch={true}
						{...props}
					/>
					<SearchButton id={idButton} onClick={onSearch}>
						{<IoIosSearchIcon colorIcon={colorIcon} />}
					</SearchButton>
					{/* <IoIosSearchIcon onClick={onSearch} /> */}
				</SearchSpan>
			</SidebarItem>
		</CompContainer>
	)
}
