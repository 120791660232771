import React from 'react'

import { ToolbarItem } from 'reakit/Toolbar'
import styled from 'styled-components'

import { theme } from 'styled-tools'
import { Link } from 'gatsby'

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 100%;
	background-color: ${theme('colors.white')};
	padding: 10px;
`

const StyledButton = styled(Link)`
	text-decoration: none;

	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: ${theme('colors.white')};
	font-size: 20px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 0.9;
	letter-spacing: normal;
	text-align: center;
	color: ${({ type, defaulttype }) => (type === defaulttype ? theme('colors.primary') : theme('colors.grayb5'))};
	text-transform: uppercase;

	border-bottom: ${({ type, defaulttype, theme }) =>
		type === defaulttype ? `4px solid ${theme.colors.primary}` : 'none'};

	width: 50%;

	min-height: 2em;

	&:hover {
		color: ${theme('colors.primary')};
	}
`
/**
 * Botões de Cliente/Médico que ficam dentro do menu mobile.
 * Recebem as props vindas da Navbar para chavear esse tipo (cliente ou medico)
 * além da props toolbar do Reakit
 */
export default function MobileClientOrDoctor({ toolbar, type = 'cliente', toggleType }) {
	return (
		<Wrapper>
			<ToolbarItem
				{...toolbar}
				as={StyledButton}
				type={type}
				defaulttype='cliente'
				onClick={() => (type === 'cliente' ? '' : toggleType())}
				to='/'
			>
				Cliente
			</ToolbarItem>
			<ToolbarItem
				{...toolbar}
				as={StyledButton}
				type={type}
				defaulttype='medico'
				onClick={() => (type === 'medico' ? '' : toggleType())}
				to='/medico/'
			>
				Médico
			</ToolbarItem>
		</Wrapper>
	)
}
