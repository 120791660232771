import React from 'react'

import styled from 'styled-components'
import { theme, ifProp } from 'styled-tools'

import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md'

const Button = styled.button`
	width: 40px;
	height: 40px;
	font-size: 18px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: ${ifProp('active', theme('colors.primary'), theme('colors.gray66'))};
	align-items: center;
	display: flex;
	justify-content: center;
	border-radius: 3px;
	border: solid 1px ${ifProp('active', theme('colors.primary'), theme('colors.graye2'))};
	background-color: ${theme('colors.white')};
	margin: 0 2px;

	:disabled {
		opacity: 0.4;
	}
	outline: none;
`

const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	grid-column: ${({ gridColumn }) => gridColumn};
`

/**
 * Componente dos botões de números da Pagination
 * @see Pagination
 */
const PaginationNumbers = ({
	totalPages,
	current,
	changePage,
	recalculate,
	toggleRecalculate,
	startIn,
	mode,
	setStartIn,
	arrow,
	setArrow,
	lastPage,
	setLastPage
}) => {
	// Regra: 6 botões no desktop, 4 no mobile, chamado de maxButtons
	// as reticencias (...) devem aparecer quando o numero de paginas é > maxButtons

	let maxButtons = 6 //padrão
	if (mode === 'mobile') maxButtons = 4

	// caso o total de páginas seja menor que 6 ou 4
	maxButtons = totalPages > maxButtons ? maxButtons : totalPages

	const [buttonsArray, setButtonsArray] = React.useState([])

	// TODO: Isso deveria ser um state?
	let ellipsisGoToPage = current + (maxButtons - 2)

	// Roda sempre que o startIn muda, ou seja,
	// Quando o botão de voltar á esquerda é clicado
	React.useEffect(() => {
		let buttons = buttonsArray.slice().map(b => ({
			...b,
			active: b.text === current
		}))

		setButtonsArray(buttons)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [current])

	React.useEffect(() => {
		if (lastPage && current === totalPages && startIn !== totalPages - maxButtons + 1) {
			setLastPage(false)
			setStartIn(totalPages - maxButtons + 1)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastPage])

	React.useEffect(() => {
		// if(current > (totalPages)/maxButtons)

		// O número mais a direita (maxRight) deve ser
		// o totalPages
		let maxRight = maxButtons + startIn
		if (maxRight <= totalPages) maxRight -= 2
		if (maxRight > totalPages) maxRight = totalPages + 1

		switch (arrow) {
			case 'left': {
				if (current === startIn) {
					setStartIn(startIn - 1)
				}
				changePage(current - 1)
				break
			}
			case 'right': {
				if (current === maxRight - 1) {
					setStartIn(startIn + 1)
				}
				changePage(current + 1)
				break
			}
			default: {
				break
			}
		}

		setArrow('')

		let buttons = []
		for (let i = startIn; i < maxRight; i++) {
			buttons.push({
				text: i,
				active: current === i,
				onClick: () => changePage(i)
			})
		}

		if (maxRight < totalPages) {
			// colocar os 3 pontinhos aqui de uma maneira diferente,
			// fazendo push() no array e depois tirando os dois primeiros usando shift()

			buttons.push({
				text: '...',
				active: false,
				onClick: () => ellipsis()
			})

			buttons.push({
				text: totalPages,
				active: current === totalPages,
				onClick: () => changePage(totalPages) // ou ellipsisAfter
			})
		}

		setButtonsArray(buttons)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startIn, arrow, totalPages])

	// tem que trocar os núemros quando estamos na ultima pag 6 e vamos p 7, por exemplo
	const ellipsis = () => {
		var initial = 1
		if (ellipsisGoToPage + maxButtons - 1 > totalPages) {
			if (totalPages - maxButtons + 1 > 1) {
				initial = totalPages - maxButtons + 1
			}
		} else {
			initial = ellipsisGoToPage
		}
		setStartIn(initial)
		changePage(ellipsisGoToPage)
	}

	return (
		<>
			{buttonsArray.map((buttonInfo, i) => (
				<Button id={`button-page-${i + 1}`} key={i} active={buttonInfo.active} onClick={buttonInfo.onClick}>
					{buttonInfo.text}
				</Button>
			))}
		</>
	)
}

/**
 * Componente de paginação.
 * Cuida da parte de renderizar os botões de paginação e controla o estado da página
 *
 * elementToRender: Função que retorna um Componente e recebe um valor do array da página
 *
 * elementsPerPage: Tamanho da página
 *
 * totalPages: Número de páginas disponíveis
 *
 * handleNextPage: Função com dois argumentos: 'page' e 'pageSize'. A partir desas informações, a função deve
 * retornar um array com os elementos dessa página
 *
 * mode: 'mobile' ou 'desktop'.
 *
 * items: items a serem listado
 *
 * page: pagina atual do componente
 *
 */
export default ({
	elementToRender,
	elementsPerPage,
	handleNextPage,
	totalPages,
	mode,
	items = [],
	page = 1,
	needFetch,
	gridColumn,
	scrollTo
}) => {
	// Estado que guarda aonde o array de botões deve começar
	const [startIn, setStartIn] = React.useState(1)
	const [arrow, setArrow] = React.useState('')
	const [lastPage, setLastPage] = React.useState(false)
	const [startPage, setStartPage] = React.useState(page)
	// const [data, setData] = React.useState(items)

	if (needFetch && startPage !== page && page === 1) {
		setStartPage(1)
		setStartIn(1)
	}

	// flag ativada sempre que o componente de botões precisa ser recalculado
	const [recalculate, toggleRecalculate] = React.useState(false)

	// Sempre que o 'page' muda, é preciso executar a função handleNextPage
	// React.useEffect(() => {
	// 	// if (!needFetch) {
	// 	// 	const nextPageElements = handleNextPage(startPage, elementsPerPage)
	// 	// 	setData(nextPageElements)
	// 	// } else {
	// 	handleNextPage(page, elementsPerPage)
	// 	// }
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [startPage])

	const handleChangePage = newPage => {
		window.scrollTo({
			behavior: 'smooth',
			top: 0
		})
		setStartPage(newPage)
		if (newPage === totalPages) setLastPage(true)

		// const updateItems =
		handleNextPage(newPage, elementsPerPage)
		// if (!needFetch) {
		// 	setData(updateItems)
		// }
	}
	const handleGoFirst = () => {
		window.scrollTo({
			behavior: 'smooth',
			top: 0
		})
		setArrow('left')
	}
	const handleGoLast = () => {
		window.scrollTo({
			behavior: 'smooth',
			top: 0
		})
		setArrow('right')
	}

	return (
		<>
			{items.map(d => elementToRender(d, startPage))}
			<ButtonsWrapper gridColumn={gridColumn}>
				<Button id='button-prev-page' onClick={handleGoFirst} disabled={startPage === 1}>
					<MdKeyboardArrowLeft />
				</Button>
				<PaginationNumbers
					totalPages={totalPages}
					current={startPage}
					changePage={handleChangePage}
					recalculate={recalculate}
					toggleRecalculate={toggleRecalculate}
					startIn={startIn}
					mode={mode}
					setStartIn={setStartIn}
					arrow={arrow}
					setArrow={setArrow}
					lastPage={lastPage}
					setLastPage={setLastPage}
				/>
				<Button id='button-next-page' onClick={handleGoLast} disabled={startPage === totalPages}>
					<MdKeyboardArrowRight />
				</Button>
			</ButtonsWrapper>
		</>
	)
}
