import styled from 'styled-components'

export const CheckboxContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${props => props.theme.colors.grayf9};
	padding: 8px 5px;
	border-radius: 3px;
	margin: 5px 0;
	cursor: pointer;
`

export const CheckboxSpan = styled.span`
	color: #77787b;
	font-size: 12px;
`

export const CheckBox = styled.input`
	display: block !important;
	position: absolute;
	opacity: 0;
	z-index: 2;
	width: 18px !important;
	height: 18px !important;

	& + label {
		position: relative;
		cursor: pointer;
		padding: 0;
		//display: flex;
		align-items: center;
		margin: 0;
		font-size: 14px;
	}

	& + label:before {
		content: '';
		margin-right: 10px;
		display: inline-block;
		vertical-align: text-top;
		width: 18px !important;
		height: 18px !important;
		background: transparent;
		border: 2px solid #a6a6a6;
		border-radius: 3px;
	}

	&:hover + label:before {
		background: ${props => props.theme.colors.primary};
	}

	&:focus + label:before {
		box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
	}

	&:checked + label:before {
		background: ${props => props.theme.colors.primary};
		width: 18px !important;
		height: 18px !important;
	}

	&:disabled + label {
		color: ${props => props.theme.colors.grayb8};
		cursor: auto;
	}

	&:disabled + label:before {
		box-shadow: none;
		background: #ddd;
	}

	&:checked + label:after {
		content: '';
		position: absolute;
		left: 6px;
		background: white;
		width: 2px;
		height: 2px;
		box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
		transform: rotate(45deg);
		top: 10px;
	}
`
