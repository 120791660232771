import React, { useState } from 'react'

import { useToolbarState, ToolbarItem } from 'reakit/Toolbar'

import SelectDesktop from './SelectDesktop'

import { Transition } from 'react-transition-group'

import { Link } from 'gatsby'
import { ToolbarOptionDropdown } from './styled'
import MoldalFaleConosco from '../Modal/ModalFaleConosco'

// custom icons
import MaisOpcoes from '../Icons/MaisOpcoes'

import {
	StyledToolbar,
	HiddenToolbar,
	SelectRegionWrapper,
	SelectRegionWrapperNoPhone,
	HalfDivGray,
	HalfDivText,
	OptionText,
	CustomSeparator,
	DropdownCustomSeparator,
	IconContainer,
	TelephoneLinkSelect,
	BlackBackground,
	LogoWrapper,
	DropdownWrapper,
	DropdownBackground,
	DropdownItemContainer
} from '../Navbar/styled/Blackbar.styled'

/**
 * Parte "preta" da barra em navegação
 * prop showLogo e showExtension é do RTG.
 * concentra a prop toolbar do Reakit.
 */
export default function Blackbar({ showLogo, regionais, logo, menuItens, isClient, theme }) {
	const toolbar = useToolbarState()

	const options = []
	regionais.metadata.telefones.map(regiao =>
		options.push({
			value: regiao.sigla,
			label: regiao.estado,
			tel: regiao.telefone,
			link: regiao.link_whatsapp,
			titulo: regiao.titulo
		})
	)

	const deskMenu = menuItens.filter(item => item.showDesk)

	const fixedItens = deskMenu ? (deskMenu.length <= 6 ? deskMenu : deskMenu.slice(0, 6)) : []

	const dropDownItens = deskMenu && deskMenu.length > 5 ? deskMenu.slice(5, deskMenu.length) : []
	const [openContactUsModal, setOpenContactUsModal] = useState(false)

	let currentOption = options[0]

	if (typeof window !== 'undefined') {
		const estado = window.location.pathname.slice(1, 3)
		// no localhost o current é vazio (não tem nada depois da barra /)
		if (['sp', 'pr', 'pe', 'pi', 'df'].includes(estado))
			//TODO: pode dar pau com labs e diag?
			// a default option vai ser a option na posição o estado current, definido acima
			currentOption = options[options.map(option => option.value).indexOf(estado) || 0]
	}

	const logoLinkTo = isClient ? '/' : '/medico/'
	// TODO: passando tema pros icones mas não usando, loucura total 🤔
	return (
		<BlackBackground data-testid='BlackBackground' isClient={isClient}>
			<MoldalFaleConosco open={openContactUsModal} onClose={() => setOpenContactUsModal(false)} />
			<StyledToolbar {...toolbar} aria-label='Segunda barra de navegação do site' isClient={isClient}>
				<Transition in={showLogo} timeout={1000}>
					{state => (
						<HiddenToolbar {...toolbar} as={Link} to={logoLinkTo} visibility={showLogo}>
							<LogoWrapper id='black-logo-wrapper' state={state}>
								{logo}
							</LogoWrapper>
						</HiddenToolbar>
					)}
				</Transition>
				<div>
					{process.env.GATSBY_MS_MARCA_PARAM.substring(0, 5) === 'Amais' ? (
						<>
							<p className='you-are-here'>Você está em:</p>
							<SelectRegionWrapperNoPhone withSelect={regionais.metadata.telefones.length > 1}>
								<HalfDivGray withSelect={regionais.metadata.telefones.length > 1} />
								<SelectDesktop regionais={regionais} currentOption={currentOption} />
							</SelectRegionWrapperNoPhone>
						</>
					) : null
					// <SelectRegionWrapper withSelect={regionais.metadata.telefones.length > 1}>
					// 	<HalfDivGray withSelect={regionais.metadata.telefones.length > 1} />
					// 	<SelectDesktop regionais={regionais} currentOption={currentOption} />
					// 	<HalfDivText>
					// 		{currentOption && currentOption.tel && (
					// 			<TelephoneLinkSelect
					// 				id='blackbar-telephone-link'
					// 				href={currentOption.link ? currentOption.link : `tel:${currentOption.tel}`}
					// 				target={currentOption.link ? '_blank' : null}
					// 			>
					// 				{`${currentOption.titulo || ''} ${currentOption.tel}`}
					// 			</TelephoneLinkSelect>
					// 		)}
					// 	</HalfDivText>
					// </SelectRegionWrapper>
					}
				</div>

				{fixedItens.map((item, index) => (
					<>
						<ToolbarItem
							{...toolbar}
							key={index}
							id={`menu-blackbar-item-${index}`}
							as={item.as}
							href={item.href}
							actionClick={item.actionClick}
							target={item.target}
							to={item.to}
						>
							<IconContainer>{item.icon}</IconContainer>
							<OptionText>{item.text}</OptionText>
						</ToolbarItem>
						{index < fixedItens.length - 1 && <CustomSeparator {...toolbar} isClient={isClient} />}
					</>
				))}
				{dropDownItens.length > 0 && (
					<>
						<CustomSeparator {...toolbar} isClient={isClient} />
						<ToolbarItem {...toolbar} as={ToolbarOptionDropdown} isClient={isClient}>
							<IconContainer>
								<MaisOpcoes theme={theme} isClient={isClient} />
							</IconContainer>
							<OptionText data-testid='MoreOptions'>Mais opções</OptionText>
							<DropdownWrapper>
								<DropdownBackground isClient={isClient}>
									{dropDownItens.map((item, index) => (
										<DropdownItemContainer key={item.text}>
											{item.actionClick?.length > 0 ? (
												<ToolbarItem {...toolbar} as={item.as} onClick={() => eval(item.actionClick)}>
													<IconContainer>{item.icon}</IconContainer>
													<OptionText>{item.text}</OptionText>
												</ToolbarItem>
											) : (
												<ToolbarItem {...toolbar} as={item.as} href={item.href} target={item.target} to={item.to}>
													<IconContainer>{item.icon}</IconContainer>
													<OptionText>{item.text}</OptionText>
												</ToolbarItem>
											)}
											{index < dropDownItens.length - 1 && <DropdownCustomSeparator {...toolbar} isClient={isClient} />}
										</DropdownItemContainer>
									))}
								</DropdownBackground>
							</DropdownWrapper>
						</ToolbarItem>
					</>
				)}
			</StyledToolbar>
		</BlackBackground>
	)
}
