import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'

const Button = styled.button`
	font-size: 14px;
	color: ${theme('colors.white')};
	background-color: ${theme('colors.primary')};
	border-radius: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 175px;
	height: 40px;
	border: none;
	margin: 0 0 0 auto;
	cursor: pointer;

	&:hover {
		background-color: ${theme('colors.activeHoverBlue')};
	}

	@media (max-width: 767.98px) {
		width: 100%;

		&:focus {
			background-color: ${theme('colors.activeHoverBlue')};
		}
	}

	a {
		color: white;
		text-decoration: none;
	}
`

export default ({ children, ...props }) => <Button {...props}>{children}</Button>
