/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useCallback, useMemo, useEffect, useState } from 'react'

import Select, { components } from 'react-select'
import styled from 'styled-components'

import pin from '../../Icons/pin.png'
import useStateWithLocalStorage from '../SelectState/useStateWithLocalStorage'
import { getHrefByRegion, getCurrentRegion } from '../../utils/region'
// import theme from '../../theme'

const selectStyles = theme => ({
	indicatorSeparator: base => ({
		...base,
		background: 'none'
	}),
	dropdownIndicator: base => ({
		...base,
		svg: {
			fill: theme.colors.primary
		}
	}),
	indicatorsContainer: base => ({
		...base,
		padding: 0
	}),
	control: base => ({
		...base,
		backgroundColor: theme.colors.white,
		border: 'none',
		width: '65px',
		boxShadow: 'none',
		'&:hover': {
			borderColor: theme.colors.graye2
		}
	}),
	menu: base => ({
		...base,
		marginTop: 0,
		textAlign: 'left',
		width: 'inherit',
		wordWrap: 'break-word',
		overflow: 'hidden'
	}),
	menuList: base => ({
		...base,
		padding: 0
	}),
	singleValue: base => ({
		...base,
		marginLeft: 0,
		marginRight: 0,
		color: theme.colors.gray66,
		fontSize: '15px',
		lineHeight: '19px'
	}),
	placeholder: base => ({
		...base,
		color: theme.colors.gray66,
		fontSize: '15px',
		lineHeight: '19px'
	}),
	option: (base, state) => ({
		...base,
		fontSize: '15px',
		lineHeight: '19px',
		backgroundColor: state.isSelected ? theme.colors.primary : theme.colors.white,
		color: state.isSelected ? theme.colors.white : theme.colors.gray66,
		':active': {
			backgroundColor: state.isSelected ? theme.colors.primary : theme.colors.white,
			color: state.isSelected ? theme.colors.white : theme.colors.gray66
		},
		'&:hover': {
			backgroundColor: theme.colors.primary,
			color: theme.colors.white
		}
	})
})

const SelectFlex = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

const SelectIcon = styled.img`
	height: 15px;
	margin-right: -2px;
	z-index: 5;
`

const ControlComponent = props => (
	<SelectFlex>
		<SelectIcon src={pin} />
		<components.Control {...props} />
	</SelectFlex>
)

/**
 * react-select dos estados para Mobile.
 * TODO: aria labels
 */
export default function SelectMobile({ regionais, currentContext, theme }) {
	const [option, setOption] = useStateWithLocalStorage() //option from LocalStorage

	// TODO: LINK DA BAHIA DINAMICO, VINDO DO COSMIC
	const handleSelectChange = useCallback(
		({ value, labelValue }) => {
			getHrefByRegion(window.location.origin, value).then(({ target, href }) => {
				// if (target === '_self') setOption({ value, labelValue })
				if (typeof window !== 'undefined') {
					// verifica se possui uma regional na url
					const region = window.location.pathname.match(/\/([a-z]{2})/)

					if (region) {
						// evita redirecionar para a mesma regional
						if (!href.includes(region[0])) window.open(href, target)
					} else window.open(href, target)
				}
			})
		},
		[option]
	)

	const options = useMemo(() =>
		regionais.metadata.telefones
			.filter(x => x.sigla !== 'ba' && x.sigla !== 'rj' && x.sigla !== 'df')
			.map(
				regiao => ({
					value: regiao.sigla,
					label: regiao.sigla.toUpperCase(),
					tel: regiao.telefone,
					labelValue: regiao.estado
				}),
				[regionais]
			)
	)

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const estado = getCurrentRegion()

			const current = options[options.map(option => option.value).indexOf(estado)]

			// const selection = current || options[0]
			const selection = current

			if (current !== undefined) setOption(selection)
		}
	}, [])

	return (
		options.length > 1 && (
			<Select
				aria-label='Escolher região'
				options={options}
				styles={selectStyles(theme)}
				components={{
					Control: ControlComponent
				}}
				onChange={handleSelectChange}
				value={option}
			/>
		)
	)
}
