import React from 'react'
import styled from 'styled-components'

/**
 * Botao transparente com borda inferior primary
 * @param {*} children - nodes dentro do botao
 * @param {*} ...props - demais props html para o botao
 * @param {boolean} fullWidth - determina se o width será 100% (default true)
 */
const Button = styled.button`
	background: transparent;
	border: 0;
	border-bottom: ${props => (props.underline ? `1px solid ${props.theme.colors.primary}` : '')};
	width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
	color: ${props => (props.primary ? props.theme.colors.primary : '#464646')};
	cursor: pointer;

	font-weight: ${({ primary }) => (primary ? 'bold' : '')};
	font-style: normal;
	font-stretch: normal;

	:hover {
		background: ${props => props.theme.colors.grayf1};
	}
`

export default ({ children, fullWidth = true, primary = true, underline = true, ...props }) => (
	<Button data-testid='teste' underline={underline} fullWidth={fullWidth} primary={primary} {...props}>
		{children}
	</Button>
)
