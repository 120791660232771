import { css } from 'styled-components'

import { theme } from 'styled-tools'

export default css`
	cursor: pointer;
	border-radius: 100px;
	border: none;
	color: ${theme('colors.white')};
	background-color: ${theme('colors.secondary')};
	min-height: 40px;
	padding: 0.5rem 2rem;
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.36;
	letter-spacing: normal;
	text-align: center;

	white-space: pre-wrap;

	&:not([disabled]):hover {
		background-color: ${theme('colors.secondary_hover')};
		border-color: ${theme('colors.secondary_hover')};
	}

	&:not([disabled]):active {
		background-color: ${theme('colors.secondary_hover')};
		border-color: ${theme('colors.secondary_hover')};
	}
`
