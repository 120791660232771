import React from 'react'

const SvgNoticias = ({ isClient, theme, ...props }) => (
	<svg width={27.5} height={24.27} {...props}>
		<defs>
			<clipPath id='prefix__a'>
				<path d='M0 0h27.5v24.27H0z' />
			</clipPath>
		</defs>
		<g
			fill={theme ? (!isClient ? theme.colors.white : theme.colors.primary) : ''}
			data-name='Prancheta \u2013 1'
			clipPath='url(#prefix__a)'
		>
			<path data-name='Caminho 5208' d='M10.64 19.289H4.355a.622.622 0 100 1.244h6.284a.622.622 0 100-1.244z' />
			<path data-name='Caminho 5209' d='M20.036 19.289h-6.284a.622.622 0 100 1.244h6.284a.622.622 0 100-1.244z' />
			<path data-name='Caminho 5210' d='M20.033 3.733H4.355a.622.622 0 100 1.244h15.678a.622.622 0 000-1.244z' />
			<path data-name='Caminho 5211' d='M20.036 6.844h-6.284a.622.622 0 000 1.244h6.284a.622.622 0 100-1.244z' />
			<path data-name='Caminho 5212' d='M20.036 9.956h-6.284a.622.622 0 000 1.244h6.284a.622.622 0 000-1.244z' />
			<path data-name='Caminho 5213' d='M20.036 13.067h-6.284a.622.622 0 000 1.244h6.284a.622.622 0 100-1.244z' />
			<path data-name='Caminho 5214' d='M20.036 16.178h-6.284a.622.622 0 000 1.244h6.284a.622.622 0 100-1.244z' />
			<path data-name='Caminho 5215' d='M10.64 16.178H4.355a.622.622 0 000 1.244h6.284a.622.622 0 000-1.244z' />
			<path
				data-name='Caminho 5216'
				d='M10.64 6.844H4.355a.622.622 0 00-.622.622v6.284a.622.622 0 00.622.622h6.284a.622.622 0 00.622-.622V7.466a.622.622 0 00-.621-.622zm-.622 6.284h-5.04v-5.04h5.04z'
			/>
			<path
				data-name='Caminho 5217'
				d='M26.258 3.111h-1.867V1.244A1.246 1.246 0 0023.147 0H1.244A1.246 1.246 0 000 1.244v19.911a3.115 3.115 0 003.111 3.111h21.28a3.115 3.115 0 003.109-3.111v-16.8a1.246 1.246 0 00-1.242-1.244zm0 18.044a1.869 1.869 0 01-1.867 1.867H3.111a1.869 1.869 0 01-1.867-1.867V1.244h21.9v18.978a1.557 1.557 0 001.556 1.556.622.622 0 100-1.244.311.311 0 01-.311-.311V4.356h1.867z'
				stroke={theme ? (!isClient ? theme.colors.white : theme.colors.primary) : ''}
				strokeWidth={0.5}
			/>
		</g>
	</svg>
)

export default SvgNoticias
