import styled from 'styled-components'
import React from 'react'
import { theme } from 'styled-tools'
import { Link } from 'gatsby'
import { isClientPersisted } from '../../utils/sessionControl'

const List = styled.ul`
	margin: 0;
	padding: 1rem 0;
	list-style: none;

	li {
		margin: 0;
		display: inline;
		font-size: 20px;
		line-height: 22px;
		font-weight: 900;

		color: ${theme('colors.black33')};

		&:after {
			content: ' > ';
			margin-left: 0.3em;
		}
		&:last-child {
			&:after {
				content: none;
			}
			color: ${theme('colors.primary')};
		}
		a {
			text-decoration: none;
			&:visited,
			&:focus {
				color: ${theme('colors.black33')};
			}
			&:hover {
				text-decoration: underline;
			}
		}
	}
`

const Wrapper = styled.div`
	display: ${props => (props.doNotShow ? 'none' : 'block')};

	@media (max-width: 1024px) {
		display: none;
	}
`

const transformPathnameToArray = pathname =>
	pathname
		.split('/')
		.filter(path => path !== '')
		// removendo as regionais
		.filter(a => a !== 'sp' && a !== 'df' && a !== 'pe' && a !== 'pr')

const removeLastFromPathname = paths => {
	paths.pop()
	// paths.push(title)
	return paths
}

const putBrazilianAccents = array =>
	array
		.join()
		.replace('noticias', 'notícias')
		.replace('convenios', 'convênios')
		.split(',')

/**
 * Formata o array de pathname: coloca acentos PT-BR, tira hífens, capitaliza
 * e remove as regionais da a+ (sp, df, pe, pr)
 * @param array array de pathname
 */
const formatPathname = array =>
	// colocando os acentos PT-BR
	putBrazilianAccents(array)
		// tirando os hífens e
		.map(path => path.replace('-', ' '))
		//  capitalizando
		.map(path => path.charAt(0).toUpperCase() + path.slice(1))

/**
 * Breadcrumb automático, pega as informações da URL e interpreta.
 * @param {} title  Título customizado da página, caso necessário
 */
export default function BreadcrumbAuto({ title }) {
	let isClient = true
	if (typeof window !== 'undefined') {
		isClient = isClientPersisted()
	}

	const homeUrl = isClient ? '/' : '/medico'

	// Criando as vars e colocando if do window SSR
	let pathname = []
	let pathnameArrayOriginal = []
	let pathnameArray = []

	if (typeof window !== 'undefined') {
		//Trata o array do pathname
		// Pegando o pathname no modo safe (vendo se window existe)
		pathname = window.location.pathname
		pathnameArray = transformPathnameToArray(pathname)
		pathnameArrayOriginal = [...pathnameArray] //se copiar direto ele copia a ref tbm

		// Se o prop title for passado, quer dizer que existe um titulo custom pra página
		if (title) {
			title = formatPathname([title])
		} else {
			// Senão, ele formata o elemento para ficar no padrão, coloca no title
			title = formatPathname([pathnameArray[pathnameArray.length - 1]])
		}
		// Logo, ele tira o último elemento do vetor
		pathnameArray = removeLastFromPathname(pathnameArray)
	}

	//Trata o array do pathname
	pathnameArray = formatPathname(pathnameArray)

	return (
		<Wrapper doNotShow={pathname === '/'}>
			<List>
				<li data-testid='breadcrumb-item'>
					<Link to={homeUrl}>Home</Link>
				</li>
				{pathnameArray.length > 0 &&
					pathnameArray[0] !== '' &&
					pathnameArray
						.filter(a => a !== 'sp' && a !== 'df' && a !== 'pe' && a !== 'pr')
						.map((path, i) => (
							<li key={`breadcrumb-${i}`} data-testid='breadcrumb-item'>
								<Link to={`/${pathnameArrayOriginal[i]}`}>{path}</Link>
							</li>
						))}
				<li data-testid='breadcrumb-item'>{title}</li>
			</List>
		</Wrapper>
	)
}
