import React from 'react'
import styled from 'styled-components'
import ShowModal from '../Modal'
import CloseWindow from '../../Icons/close-window.svg'

import NewModal from './newModal'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

// import useStateWithLocalStorage from '../SelectState/useStateWithLocalStorage'

const CloseIcon = styled.img`
	cursor: pointer;
	display: block;
	float: right;
	margin: 0;
`

const ImageContent = styled.div`
	margin: -20px 20px 0px 0px;
`

const WrapContent = styled.div`
	/* padding: 20px 0px 0px; */
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-left: 38px;
	padding-bottom: 38px;
`

const Title = styled.p`
	margin-top: -1px;
	font-size: 24px;
	font-weight: bold;
	line-height: 31px;
	text-align: start;
	color: #302a38;
	width: 100%;

	@media (max-width: 320px) {
		margin: 0px;
	}
`

const Subtitle = styled.span`
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	color: #666666;
	width: 100%;
`

const SubtitleIrn = styled.span`
	margin-bottom: 15px;
	font-size: 14px;
	line-height: 20px;
	text-align: left;
	color: #666666;
	width: 100%;
`

const ContactWrapper = styled.div`
	display: flex;
	align-items: center;
	margin: 0px 25px;
	text-align: left;
	padding: 20px 0px;
	border-bottom: solid 1px #cecece;
	width: 100%;

	:first-of-type {
		margin: 20px 25px 0px;
	}

	:last-of-type {
		border-bottom: none;
	}

	@media (max-width: 320px) {
		padding: 10px 0px;
	}
`

const ContactWrapperIrn = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 12px;
	margin-left: 25px;
	text-align: left;
	padding: 5px 0px;
	width: 100%;

	/* :first-of-type {
		margin: 10px 25px 5px;
	} */

	@media (max-width: 320px) {
		padding: 10px 0px;
	}
`

const ContactIcon = styled.img`
	margin: 0px 16px 0px 0px;
`

const ContactIconIrn = styled.img`
	margin-left: -24px;
	margin-top: -5px;
	width: 22px;
`

const ContactInfo = styled.div`
	width: 90%;
`

const TitleWrapper = styled.div`
	a {
		color: #0088c8;
		font-weight: bold;
		text-decoration: none;
		display: inline-block;
	}

	strong,
	p {
		margin: 0px;
		font-size: 14px;
		font-weight: 900;
		line-height: 20px;
		text-align: left;
		color: #0088c8;
		display: block;
		padding: 0px 0px 5px;
		font-weight: bold;
	}
`

const SubtitleWrapper = styled.div`
	p {
		font-size: 12px;
		line-height: 13px;
		text-align: left;
		color: #666666;
		margin: 0px;
	}

	a {
		color: #0088c8;
		font-weight: bold;
	}
`

// const OpeningHours = styled.div`
// 	margin-top: 5px;
// 	margin-left: -65px;
// 	p {
// 		font-size: 14px;
// 		line-height: 21px;
// 		text-align: left;
// 		color: #666666;
// 		margin: 0px;
// 	}
// `

const IconWrapper = styled.div`
	width: 60px;
	// width: 20%;
	// max-width: 10%;
`

const IconWrapperIrn = styled.div`
	width: 10px;
`

const SchedulesContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	color: #464646;
	width: 100%;
	line-height: 20px;
	padding-left: 8px;
	border-radius: 8px;
	background-color: #e5f3f1;

	ul {
		padding-left: 24px !important;
		text-align: left;
		margin-top: -8px;
	}
`

export default function ScheduleModal({ showSchedule, setShowSchedule, contatos, contatosAgendar }) {
	// const [estado, setEstado] = useState('')

	// useEffect(() => {
	// 	setEstado(window ? window?.location?.pathname.slice(1, 3) : '')
	// }, [])

	// const [storage] = useStateWithLocalStorage()

	const contacts = contatos
		? contatos.metadata.canal_de_contato
				// pelo que encontrei no codigo, apenas a selecao de estado modifica o localStorage, ou seja, as demais marcas sempre exibirao por nao possuirem o state
				// .filter(item => !(storage && storage.value !== 'sp' && item.titulo.toLowerCase().includes('whatsapp')))
				.map((contato, i) => (
					<ContactWrapper key={i}>
						<IconWrapper>
							<ContactIcon alt='Contato' src={imgixUrlOptimizerAuto(contato.icone.imgix_url)} />
						</IconWrapper>
						<ContactInfo>
							<TitleWrapper dangerouslySetInnerHTML={{ __html: contato.titulo }}></TitleWrapper>
							<SubtitleWrapper dangerouslySetInnerHTML={{ __html: contato.texto }}></SubtitleWrapper>
						</ContactInfo>
					</ContactWrapper>
				))
		: []

	const contactsIrn = contatos
		? contatos.metadata.canal_de_contato
				// pelo que encontrei no codigo, apenas a selecao de estado modifica o localStorage, ou seja, as demais marcas sempre exibirao por nao possuirem o state
				// .filter(item => !(storage && storage.value !== 'sp' && item.titulo.toLowerCase().includes('whatsapp')))
				.map((contato, i) => (
					<ContactWrapperIrn key={i}>
						<IconWrapperIrn>
							<ContactIconIrn alt='Contato' src={imgixUrlOptimizerAuto(contato.icone.imgix_url)} />
						</IconWrapperIrn>
						<ContactInfo>
							<TitleWrapper dangerouslySetInnerHTML={{ __html: contato.titulo }}></TitleWrapper>
							{/* <SubtitleWrapper dangerouslySetInnerHTML={{ __html: contato.texto }}></SubtitleWrapper> */}
						</ContactInfo>
					</ContactWrapperIrn>
				))
		: []

	//return ['amaissp', 'amaispe', 'amaispr', 'diagnoson', 'labsamais'].includes(
	return ['amaispe', 'amaispi', 'amaispr', 'amaissp', 'diagnoson', 'labs-amais'].includes(
		process.env.GATSBY_COSMIC_BUCKET
	) ? (
		<ShowModal show={showSchedule}>
			<NewModal
				showSchedule={showSchedule}
				setShowSchedule={setShowSchedule}
				contatos={contatos}
				contatosAgendar={contatosAgendar}
			/>
		</ShowModal>
	) : process.env.GATSBY_COSMIC_BUCKET === 'irn' ? (
		<ShowModal show={showSchedule}>
			<ImageContent>
				<CloseIcon onClick={() => setShowSchedule(false)} alt='Fechar' src={CloseWindow} />
			</ImageContent>
			<WrapContent>
				<Title id='title-schedule-modal'>{contatos && contatos.metadata.titulo}</Title>
				<SubtitleIrn>{contatos && contatos.metadata.subtitulo}</SubtitleIrn>
				{contactsIrn}
				<SchedulesContent
					dangerouslySetInnerHTML={{ __html: contatos.metadata.canal_de_contato[0].texto }}
				></SchedulesContent>
			</WrapContent>
		</ShowModal>
	) : (
		<ShowModal show={showSchedule}>
			<ImageContent>
				<CloseIcon onClick={() => setShowSchedule(false)} alt='Fechar' src={CloseWindow} />
			</ImageContent>
			<WrapContent>
				<Title id='title-schedule-modal'>{contatos && contatos.metadata.titulo}</Title>
				<Subtitle>{contatos && contatos.metadata.subtitulo}</Subtitle>
				{contacts}
			</WrapContent>
		</ShowModal>
	)
}
