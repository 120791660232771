import React from 'react'

import styled from 'styled-components'
import { IoIosSearch } from 'react-icons/io'
//import search from '../../assets/img/search.svg'
import { Input } from '.'

const CompContainer = styled.div`
	@media (max-width: 991.98px) {
		width: 100%;
	}
`

const SidebarItem = styled.div`
	padding: 0 0 4px;
`

const SearchSpan = styled.span`
	color: ${props => props.theme.colors.primary} !important;
	padding: 5px 6px 1px 24px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	input {
		margin-top: 1px;
		color: ${props => props.theme.colors.primary} !important;
		font-family: 'fs_lolaregular' !important;
		font-size: 18px;
		font-weight: 600;

		::placeholder {
			color: ${props => props.theme.colors.primary} !important;
			font-weight: 600;
		}
	}
`

const SearchButton = styled.button`
	background: none;
	border: none;
	padding: 0;
	margin-top: auto;
	z-index: 0;
	cursor: pointer;
`

const IconWrapper = styled.div`
	background-color: ${props => props.theme.colors.primary};
	border-radius: 45px;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
`

const IoIosSearchIcon = styled(IoIosSearch)`
	color: #ffff;
	width: 20px;
	height: 20px;
`

/**
 * Componente de busca
 * @param {function} onSearch - callback para executar ao digitar
 * @param {function} onKeyPress - callback para executar ao digitar Insert
 * @param {icon} iconLeft - item a ser exibido à esqueda do input
 * @param {string} idButton - id para a lupa
 * @param {*} ...props - outras props html para o input
 */
export default ({
	onSearch,
	onKeyPress,
	iconLeft: IconLeft,
	colorIcon,
	backgroundSearch,
	borderSearch,
	paddingSearch,
	idButton,
	...props
}) => {
	const handlePressEnter = e => e.key === 'Enter' && onKeyPress && onKeyPress(e)
	return (
		<CompContainer>
			<SidebarItem>
				<SearchSpan backgroundSearch={backgroundSearch} borderSearch={borderSearch} paddingSearch={paddingSearch}>
					{IconLeft && <IconLeft />}
					<Input iconLeft={!!IconLeft} onKeyPress={handlePressEnter} inSearch={true} color='#009EDB' {...props} />
					<SearchButton id={idButton} onClick={onSearch}>
						<IconWrapper>
							<IoIosSearchIcon colorIcon={colorIcon} />
						</IconWrapper>
					</SearchButton>
				</SearchSpan>
			</SidebarItem>
		</CompContainer>
	)
}
