import React from 'react'

const useStateWithLocalStorage = initialState => {
	let statePersisted = ''
	if (typeof window !== 'undefined') statePersisted = JSON.parse(window.sessionStorage.getItem('state'))

	let [state, setState] = React.useState(statePersisted || initialState)

	React.useEffect(() => {
		// in building process there is no window
		if (typeof window !== 'undefined') {
			const persistedState = JSON.parse(window.sessionStorage.getItem('state'))
			// verificar se já tem algo no localStorage e se é o mesmo que está no state
			if (persistedState && persistedState.value === state.value) return
			else if (state) {
				//se o estado é falsy por algum motivo, não colocar no localStorage
				window.sessionStorage.setItem('state', JSON.stringify({ ...state }))
			}
		}
	}, [state])

	return [state, setState]
}

export default useStateWithLocalStorage
