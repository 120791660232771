import React from 'react'
import styled from 'styled-components'
import { IoIosClose } from 'react-icons/io'
import { Icon } from '../Buttons'

const CloseIcon = styled(IoIosClose)`
	color: ${props => props.theme.colors.graya6};
	width: 35px;
	height: 35px;
`

const Chip = styled.div`
	display: flex;
	align-items: center;
	height: 32px;
	border-radius: 24px;
	color: ${props => props.theme.colors.graya6};
	border: solid 2px ${props => props.theme.colors.graya6};
	padding: 0 0 0 16px;
`
/**
 * Componente de Chip
 * @param {children} label - valor a ser exibido dentro do chip
 * @param {func} onClose - callback ao clicar no botao de exclusao do chip
 */
export default ({ children, onClose }) => (
	<Chip>
		{children}
		<Icon hideOnMobile={true} onClick={onClose}>
			<CloseIcon />
		</Icon>
	</Chip>
)
