import React, { useRef } from 'react'
import styled from 'styled-components'
import { FormGroup } from '../Blocks'

export const Label = styled.label`
	white-space: pre;
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	line-height: ${props => (props.lineHeight ? props.lineHeight : '60px')};
	background: transparent;
	margin: 0px auto;
	cursor: text;
	transition: all 0.15s ease-in-out;
	height: 21px;
	color: ${props => (props.color ? props.color : '#a2a2a2')};
	font-size: ${props => (props.fontSize ? props.fontSize : '18px')};
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	text-align: left;
`

const Input = styled.textarea`
	font-size: ${props => (props.fontSize ? props.fontSize : '12px')};
	color: ${props => (props.color ? props.color : '')};
	height: 24px;
	max-height: 80px;
	min-width: 100%;
	border: none;
	border-bottom: ${props => (props.borderBottom ? props.borderBottom : '')};;
	background: transparent;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: left;
	position: relative;
	display: block;
	margin: 21px auto auto auto;
	background: linear-gradient(to top, ${props => props.theme.colors.primary} 50%, ${props =>
	props.theme.colors.primary} 50%);
	background-position: left bottom;
	background-size: 0 1px;
	background-repeat: no-repeat;
	padding: ${props => (props.padding ? props.padding : '0px 10px')};
	transition: all 0.2s ease-in-out;

	&:focus {
		background-position: left bottom;
		background-size: 100% 1px;
	}

	/* &:not([value=''])+${Label},  */
	&:required:valid+${Label}, 
	&:focus+${Label} {
		margin-top: ${props => (props.marginTop ? props.marginTop : '')};
		height: 20px;
		line-height: 20px;
		font-size: 11px;
	}

	&:focus &::-webkit-input-placeholder {
		color: transparent;
		font-size: 0;
	}

	&:focus &:-moz-placeholder {
		color: transparent;
		font-size: 0;
	}

	&:focus &::-moz-placeholder {
		color: transparent;
		font-size: 0;
	}

	&:focus &:-ms-input-placeholder {
		color: transparent;
		font-size: 0;
	}

	outline: none;
	width: 100%;

	@media (max-width: 767.98px) {
		background:  ${props => (props.background ? props.background : '#f1f1f1')};
		// font-size: 14px;
	}

	@media (min-width: 375px) {
		padding: 0;
	}

	&::-webkit-resizer {
		display: none;
	}
`

/**
 * Componente de busca
 * @param {(event)=>{}} onSearch - callback para executar ao digitar
 * @param {()=>{}} onKeyPress - callback para executar ao digitar Insert
 * @param {string | React.ReactNode | React.ReactElement} iconLeft - item a ser exibido à esqueda do input
 * @param {any} ...props - quaisquer outras props seram colocadas para o elemento de input
 */
const Required = styled.span`
	color: ${props => props.theme.colors.primary};
`

const autosize = el => {
	setTimeout(function() {
		el.style.cssText = 'height:auto; padding:0'
		// for box-sizing other than "content-box" use:
		// el.style.cssText = '-moz-box-sizing:content-box';
		el.style.cssText = `min-height: ${el.scrollHeight * 0.9}px`
	}, 0)
}

export default ({
	label,
	id,
	name,
	lineHeight,
	fontSize,
	color,
	required,
	inputFontSize,
	inputColor,
	inputPadding,
	inputBorderBottom,
	inputMarginTop,
	inputBackground,
	inputType,
	...props
}) => {
	const ref = useRef(null)
	// useEffect(() => {
	// 	if (ref) {
	// 		ref.current.addEventListener('keydown', autosize(ref.current))
	// 	}
	// }, [])
	if (ref && ref.current) autosize(ref.current)
	return (
		<FormGroup>
			<Input
				required={required}
				type={inputType}
				id={id}
				className='input-float'
				name={name}
				fontSize={inputFontSize}
				color={inputColor}
				padding={inputPadding}
				borderBottom={inputBorderBottom}
				marginTop={inputMarginTop}
				background={inputBackground}
				ref={ref}
				{...props}
			/>
			{label && (
				<Label htmlFor={id || name} className='label-float' lineHeight={lineHeight} fontSize={fontSize} color={color}>
					{label}
					{required === true ? <Required>*</Required> : null}
				</Label>
			)}
		</FormGroup>
	)
}
