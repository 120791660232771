import React from 'react'

import { Box } from 'reakit/Box'

import styled from 'styled-components'

import { Transition } from 'react-transition-group'
import animation from './animation.styled'
import { theme } from 'styled-tools'
import { Link } from 'gatsby'
import getBrand from '../../utils/getBrand'

const Wrapper = styled(Box)`
	background-color: ${theme('colors.grayf8')};
	padding: 40px 8%;

	transition: 0.8s;

	${animation}
	flex-direction: column;
`

const Title = styled.p`
	font-size: 40px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.black_fonts};
	margin: 0;

	&::after {
		content: '';
		width: 87px;
		height: 4px;
		background-color: ${theme('colors.primary')};
		position: absolute;
		bottom: -8px;
		left: 0;
	}
`

const TitleWrapper = styled.div`
	position: relative;
`

const MainGrid = styled.div`
	display: grid;
	grid-template-columns: 2fr 330px;
	grid-template-rows: auto;
	grid-gap: 2%;
`

const CategoriesGrid = styled.div`
	display: grid;
	/* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: auto;
	grid-gap: 5px;
	margin-top: 30px;
`

const Column = styled.div`
	display: flex;
	flex-direction: column;
	padding-right: 10px;

	:not(:first-of-type) {
		padding-left: 20px;
		/* align-items: center; */
	}

	&:first-of-type {
		padding-right: 10px;
		/* align-items: flex-start; */
	}

	:not(:last-of-type) {
		border-right: 1px solid ${theme('colors.light_gray')};
		border-bottom: 0;
	}

	@media only screen and (max-width: 1024px) {
		:not(:last-of-type) {
			border-right: 0;
			border-bottom: 1px solid ${theme('colors.light_gray')};
		}
	}

	@media only screen and (max-width: 1500px) {
		padding-left: 5px !important;
		padding-right: 5px !important;
		/* align-items: center; */
	}

	/* para o resposivo, todos os textos devem ter o mesmo alinamento */
	/* :not(:first-of-type) {
    text-align: center;
  } */

	padding: 0 10px;
`

const Category = styled(Link)`
	font-size: 20px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 0.9;
	letter-spacing: normal;
	color: ${props => props.theme.colors.black_fonts};
	margin: 1em 0;
	text-decoration: none;
	cursor: pointer;
`

const CategoryExternal = styled.a`
	font-size: 20px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 0.9;
	letter-spacing: normal;
	color: ${props => props.theme.colors.black_fonts};
	margin: 1em 0;
	text-decoration: none;
	cursor: pointer;
`

export default function AllCategories({ show, urlResults }) {
	return (
		<>
			<Transition in={show} unmountOnExit timeout={200}>
				{state => (
					<Wrapper state={state} show={show}>
						<MainGrid>
							<Box>
								<TitleWrapper>
									<Title id='all-categories'>Todas as Categorias</Title>
								</TitleWrapper>
								<CategoriesGrid>
									<Column>
										<CategoryExternal href={urlResults} target='_blank'>
											Resultado de Exames
										</CategoryExternal>
										<Category to='/convenios/'>Consulte seu Convênio</Category>
									</Column>
									<Column>
										<Category to='/exames/'>Lista de Exames</Category>
										<Category to='/unidades/'>Unidades</Category>
										{/* <Category>Notícias</Category> */}
									</Column>
									<Column>
										{/* <Category>Resultado de Exame</Category> */}
										<Category to='/institucional/'>Institucional</Category>
										<CategoryExternal href={getBrand().ouvidoria} target='_blank'>
											Ouvidoria
										</CategoryExternal>
										{/* <Category to='/fale-conosco/'>Ouvidoria</Category> */}
									</Column>
									{/* <Column> */}
									{/* <Category>Dúvidas Frequentes</Category> */}
									{/* <Category>Revistas</Category> */}
									{/* </Column> */}
								</CategoriesGrid>
							</Box>
							{/* <Box style={{ margin: '0 0 0 auto' }}>
								<img src='https://placekitten.com/322/195' alt='atendimento móvel' />
							</Box> */}
						</MainGrid>
					</Wrapper>
				)}
			</Transition>
		</>
	)
}
