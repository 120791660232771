/**
 * Funcionalidade igual ao componente <ImgixImg /> mas no formato de função, caso a
 * funcionalidade desejada seja apenas a url do imgix e não uma URL em si
 * @see Blocks/ImgixImg
 * @returns URL otimizada
 */
const imgixUrlOptimizerAuto = (src, w, h) =>
	// let link = src.replace('fm=webp&lossless=0&w=undefined&h=undefined', '')
	//return `${src}?q=45&auto=format${w ? `&w=${w}` : ''}${h ? `&h=${h}` : ''}`
	// substituido pela linha abaixo para melhorar a qualidade das imagens (teste)
	`${src}?auto=format${w ? `&w=${w}` : ''}${h ? `&h=${h}` : ''}`

export default imgixUrlOptimizerAuto
