import React from 'react'
import styled from 'styled-components'

// import useStateWithLocalStorage from '../SelectState/useStateWithLocalStorage'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 16px 16px;
	width: 91px;
	height: 91px;
	background: #ffffff;
	box-shadow: 0px 3px 6px rgba(131, 127, 136, 0.2);
	border-radius: 4px;
	flex: none;
	order: 0;
	flex-grow: 0;

	@media (max-width: 500px) {
		width: 85px;
	}
`

const Image = styled.img`
	width: 24px;
`

const Title = styled.div`
	font-family: Trebuchet MS;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 22px;
	text-align: center;
	color: #302a38;
	flex: none;
	align-self: stretch;
	margin-top: 5px;

	a,
	strong {
		color: #302a38;
		text-decoration: none;
		display: inline-block;
	}

	p {
		span {
			color: #302a38 !important;
		}
	}
`

const BigTitle = styled.div`
	font-family: Trebuchet MS;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 20px;
	text-align: center;
	color: #302a38;
	flex: none;
	align-self: stretch;
	margin-top: 4px;

	a,
	strong {
		color: #302a38;
		text-decoration: none;
		display: inline-block;
	}

	p {
		span {
			color: #302a38 !important;
		}
	}
`

const Subtitle = styled.span`
	font-family: Trebuchet MS;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	color: #302a38;
`

function ModalCard({ title, image, subtitle }) {
	return (
		<Wrapper>
			<Image alt='Contato' src={image} />
			{title?.length > 21 ? (
				<BigTitle dangerouslySetInnerHTML={{ __html: title }} />
			) : (
				<Title dangerouslySetInnerHTML={{ __html: title }} />
			)}
			<Subtitle>{subtitle}</Subtitle>
		</Wrapper>
	)
}

export default ModalCard
