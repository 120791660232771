import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { theme } from 'styled-tools'

const ToolbarOptionCSS = css`
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: normal;
	align-items: center;
	/* background-color: rebeccapurple; */
	/* width: 100px; */
	margin: 0 10px !important;

	/* o hover pode ser no botao e icone tbm */
	// TODO checar o hover a ser aplicado para cada background
	// &:hover {
	// 	background-color: ${({ isClient }) => (isClient ? '#090909' : theme('colors.gray46'))};
	// }
`

const ToolbarOptionLink = styled(Link)`
	${ToolbarOptionCSS}
	text-decoration: none;
`

const ToolbarOptionLinkExterno = styled.a`
	${ToolbarOptionCSS}
	text-decoration: none;
`

const ToolbarOptionLinkAction = styled.a`
	${ToolbarOptionCSS}
	text-decoration: none;
`

const ToolbarOptionDropdown = styled.div`
	${ToolbarOptionCSS}
	max-width: 108px;
	:focus {
		outline: none !important;
	}

	:hover div {
		display: block;
	}
`

export { ToolbarOptionLink, ToolbarOptionLinkExterno, ToolbarOptionDropdown, ToolbarOptionLinkAction }
