import { Toolbar, ToolbarItem, ToolbarSeparator } from 'reakit/Toolbar'
import { Box } from 'reakit/Box'
import styled from 'styled-components'
import animation from '../animation.styled'
import { theme, ifProp } from 'styled-tools'

const StyledToolbar = styled(Toolbar)`
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	font-family: '-apple-system', 'system-ui, BlinkMacSystemFont', 'Segoe UI', 'Helvetica Neue', Helvetica, Arial,
		sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	display: flex;
	flex-direction: row;

	&& {
		padding: 0 5px;
		background-color: ${({ isClient }) => (isClient ? theme('colors.gray1') : theme('colors.gray7b'))};
		min-height: 70px;

		margin: 0 auto;
		max-width: ${theme('container')};
		align-items: center;
		justify-content: space-between;

		@media (max-width: 1250px) {
			padding: 5px 25px 5px 0;
		}

		@media (max-width: 1024px) {
			padding: 1px 5px;
		}
	}

	.you-are-here {
		margin: 0 0 2px 0;
		color: white;
		font-size: 12px;
	}
`

const HiddenToolbar = styled(ToolbarItem)`
	visibility: ${props => (props.visibility ? 'unset' : 'hidden')};
`

const SelectRegionWrapperNoPhone = styled.div`
	position: relative;
	border-radius: 17px;
	width: 143px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0.5em;
	align-items: center;
	/* margin: 0 ${ifProp('withSelect', '3%', '7%')} 0 ${ifProp('withSelect', '4%', '6%')}; */
	height: 38px;
	padding: 0 ;
	background-color: #edeff4;
	margin-right: 15px;

	@media (max-width: 1024px) {
		margin: 0 0 0 1%;
	}

	#select-region-div {
		width: 100% !important;
	}
`

const SelectRegionWrapper = styled.div`
	position: relative;
	border-radius: 100px;
	background-color: ${theme('colors.white')};
	width: ${ifProp('withSelect', '280px', '175px')};
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0.5em;
	align-items: center;
	/* margin: 0 ${ifProp('withSelect', '3%', '7%')} 0 ${ifProp('withSelect', '4%', '6%')}; */
	padding: 0 20px;

	.region-container{
		display: flex;
		flex-direction: row;
	}

	@media (max-width: 1024px) {
		margin: 0 0 0 1%;
	}
`

const HalfDivGray = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: ${ifProp('withSelect', '50%', '35%')};
	height: 100%;
	border-radius: 50px;
	background-color: ${ifProp('withSelect', 'none', '#f8f8f8')};
`

const HalfDivText = styled.div`
	display: flex;
	justify-content: center;
`

const OptionText = styled.p`
	max-width: 73px;
	font-size: 13px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.23;
	letter-spacing: normal;
	text-align: left;
	color: ${theme('colors.white')};
	margin-left: 8px;

	@media (max-width: 1250px) {
		max-width: 70px;
		font-size: 11px;
		font-weight: 500;
	}
	line-height: 16px;
	/* margin-left: 0.5em; */
`

const CustomSeparator = styled(ToolbarSeparator)`
	&& {
		border: 1px solid ${({ isClient }) => (isClient ? '#333' : '#fff')};
		border-width: 0 1px 0 0;
		height: 3rem;
		align-self: center;
		margin: 0 !important;
	}
`

const DropdownCustomSeparator = styled(ToolbarSeparator)`
	display: none;
`

const IconContainer = styled(Box)`
	&& {
		min-width: 25px;
		min-height: 25px;
		margin-top: 5px;
		align-items: center !important;
		justify-content: center !important;
		color: ${theme('colors.primary')};
	}

	@media (max-width: 1250px) {
		zoom: 0.7;
	}
`

const TelephoneLinkSelect = styled.a`
	color: ${theme('colors.gray66')};
	text-decoration: none;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.14;
	letter-spacing: normal;
	/* text-align: left; */
`

const BlackBackground = styled.div`
	background-color: ${({ isClient }) => (isClient ? theme('colors.gray1') : theme('colors.gray7b'))};
	width: 100%;
`

const LogoWrapper = styled.div`
	padding-left: 10px;
	display: flex;
	align-items: center;
	height: 58px;
	width: 100%;
	${animation}
`

const DropdownWrapper = styled.div`
	width: 125px;
	align-self: normal;
	position: absolute;
	padding-top: 50px;
	display: none;
	margin-left: -10px;
	:hover {
		display: block;
	}
`

const DropdownBackground = styled.div`
	padding: 15px 0 0;
	background-color: ${({ isClient }) => (isClient ? theme('colors.gray1') : theme('colors.gray7b'))};
`

const DropdownItemContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	border-bottom: 1px solid #333333;
	padding-top: 5px;
	padding-bottom: 5px;
	a:hover {
		border: none;
	}
`

export {
	StyledToolbar,
	HiddenToolbar,
	SelectRegionWrapper,
	SelectRegionWrapperNoPhone,
	HalfDivGray,
	HalfDivText,
	OptionText,
	CustomSeparator,
	DropdownCustomSeparator,
	IconContainer,
	TelephoneLinkSelect,
	BlackBackground,
	LogoWrapper,
	DropdownWrapper,
	DropdownBackground,
	DropdownItemContainer
}
