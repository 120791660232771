// import React from 'react'
import styled from 'styled-components'

/**
 * Componente de Grid
 * @param {string} gap - grid-gap, regra: gap || '0 20px'
 * @param {string} gapMobile - grid-gap para ate 460px, regra: gapMobile || gap || '20px'
 * @param {string} columns - grid-template-columns, regra: repeat(columns, 1fr) || auto-fill 200px 1fr (considerando minWidthCol nao enviado)
 * @param {string} minWidthCol - outra opcao grid-template-columns, regra: auto-fill minWidthCol 1fr || auto-fill 200px 1fr (considerando columns nao enviado)
 * @param {string} minWidthColMobile - mesmas regras do que minWidthCol, com padra: 160px 160px
 * @param {string} justify - justify-content
 * @param {string} flow - min 768px, grid-auto-flow
 * @param {string} rows - min 768pc, repeat(rows, 1fr) || ''
 */

const Grid = styled.div(({ m, gap, gapMobile, columns, minWidthCol, minWidthColMobile, justify, flow, rows }) => {
	const tempCols = columns
		? `repeat(${columns}, 1fr)`
		: minWidthCol
		? `repeat(auto-fill, minmax(${minWidthCol}, 1fr))`
		: 'repeat(auto-fill, minmax(200px, 1fr))'
	const tempColsMobile = columns
		? `repeat(${columns}, 1fr)`
		: minWidthColMobile
		? `repeat(auto-fill, minmax(${minWidthColMobile}, 1fr))`
		: '160px 160px'
	const tempRows = rows ? `repeat(${rows}, 1fr)` : ''
	const gGap = gap || '0 20px'
	const gGapMobile = gapMobile || gap || '20px'
	const margin = m
	return {
		display: 'grid',
		// grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)),
		margin,
		'grid-template-columns': tempCols,
		'grid-gap': gGap,
		'justify-content': justify,
		'@media (min-width: 768px)': {
			'grid-auto-flow': flow,
			'grid-template-rows': tempRows
		},
		'@media (max-width: 460px)': {
			'grid-template-columns': tempColsMobile,
			'grid-gap': gGapMobile
		}
	}
})

const Row = styled.div`
	display: flex;
	flex-wrap: wrap;
	// &::after {
	// 	content: '';
	// 	clear: both;
	// 	display: table;
	// }
	justify-content: ${({ justify }) => justify};
`
const Col = styled.div`
	float: left;
	padding: ${({ p }) => p || '15px'};
	width: ${({ size }) => `${8.33 * (size ? size : 12)}%`};

	@media (max-width: 768px) {
		padding: 0;
		width: ${({ size }) => (size > 2 ? '100%' : `${8.33 * (size ? size : 12)}%`)};
	}
`

export { Grid, Row, Col }
