import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { navigate } from 'gatsby'

const ListItem = styled.div`
	&:hover,
	&:active {
		border-left: 5px solid ${theme('colors.primary')};
		color: ${theme('colors.primary')};
		padding-left: 20px;
		background-color: ${theme('colors.white')};
	}
	color: ${props => props.theme.colors.black_fonts};
	display: flex;
	justify-content: flex-start;
	padding-left: 25px;
`

const ListText = styled.p`
	font-size: 15px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.2;
	letter-spacing: normal;
	text-align: left;
	color: inherit;

	text-decoration: none;
	display: block;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
`

const MenuItensWrapper = styled.div`
	height: calc(100vh - 254px);
	overflow: scroll;
	padding: 0 0 30px;
`

/**
 * Itens do menu mobile. Recebem a props toggleMenu, que fecha o menu quando alguma das opções é selecionada.
 * @param {*} param0
 */
export default function MobileList({ toggleMenu, menuItens }) {
	const options = [{ name: 'Início', link: '/', href: '', target: '' }]

	menuItens
		.filter(item => item.showMobile)
		.forEach(item => {
			options.push({
				name: item.text,
				link: item.to,
				href: item.href,
				target: item.target,
				actionClick: item.actionClick
			})
		})

	options.map((option, i) => (option.key = i))

	return (
		<MenuItensWrapper>
			{options.map(option => (
				<ListItem
					key={`mobile-item-list-${option.name}`}
					onClick={() =>
						(typeof window !== 'undefined' && option.href.length > 0 && window.open(option.href, option.target)) ||
						navigate(option.link) ||
						toggleMenu()
					}
				>
					<ListText>{option.name}</ListText>
				</ListItem>
			))}
		</MenuItensWrapper>
	)
}
