import React from 'react'

const SvgMenuConvenios = ({ isClient, theme, ...props }) => (
	<svg width={32.495} height={25.371} {...props}>
		<defs>
			<clipPath id='menuConvenios_svg__clip-path'>
				<path
					id='menuConvenios_svg__Caminho_97'
					d='M368.609 103.967H349.2a.778.778 0 010-1.556h19.408a.778.778 0 010 1.556z'
					className='menuConvenios_svg_comp__cls-1'
					data-name='Caminho 97'
					transform='translate(-348.426 -102.411)'
				/>
			</clipPath>
			<clipPath id='menuConvenios_svg__clip-path-2'>
				<path
					id='menuConvenios_svg__Ret\xE2ngulo_387'
					d='M0 0h1538.705v85.484H0z'
					className='menuConvenios_svg_comp__cls-1'
					data-name='Ret\xE2ngulo 387'
				/>
			</clipPath>
			<clipPath id='menuConvenios_svg__clip-path-3'>
				<path
					id='menuConvenios_svg__Caminho_98'
					d='M356.2 104.625h-7a.778.778 0 010-1.556h7a.778.778 0 010 1.556z'
					className='menuConvenios_svg_comp__cls-1'
					data-name='Caminho 98'
					transform='translate(-348.426 -103.069)'
				/>
			</clipPath>
			<clipPath id='menuConvenios_svg__clip-path-5'>
				<path
					id='menuConvenios_svg__Caminho_99'
					d='M359.939 104.625h-8.721a.778.778 0 110-1.556h8.721a.778.778 0 010 1.556z'
					className='menuConvenios_svg_comp__cls-1'
					data-name='Caminho 99'
					transform='translate(-350.437 -103.069)'
				/>
			</clipPath>
			<clipPath id='menuConvenios_svg__clip-path-7'>
				<path
					id='menuConvenios_svg__Caminho_100'
					d='M358.213 102.509h-7a.775.775 0 110-1.551h7a.775.775 0 010 1.551z'
					className='menuConvenios_svg_comp__cls-1'
					data-name='Caminho 100'
					transform='translate(-350.437 -100.958)'
				/>
			</clipPath>
			<style>
				{`.menuConvenios_svg_comp__cls-1{fill:${
					theme ? (!isClient ? theme.colors.white : theme.colors.primary) : ''
				}}.menuConvenios_svg_comp__cls-1,.menuConvenios_svg_comp__cls-2{stroke:${
					theme ? (!isClient ? theme.colors.white : theme.colors.primary) : ''
				}}.menuConvenios_svg_comp__cls-2,.menuConvenios_svg_comp__cls-9{fill:none}.menuConvenios_svg_comp__cls-2{stroke-width:1px}.menuConvenios_svg_comp__cls-4{clip-path:url(#menuConvenios_svg__clip-path-2)}.menuConvenios_svg_comp__cls-8{stroke:${
					theme ? (!isClient ? theme.colors.white : theme.colors.primary) : ''
				}}`}
			</style>
		</defs>
		<g id='menuConvenios_svg__Grupo_742' data-name='Grupo 742' transform='translate(2 2)'>
			<g
				id='menuConvenios_svg__Ret\xE2ngulo_385'
				className='menuConvenios_svg_comp__cls-2'
				data-name='Ret\xE2ngulo 385'
			>
				<rect width={28.495} height={21.371} className='menuConvenios_svg_comp__cls-8' rx={2} />
				<rect width={30.495} height={23.371} x={-1} y={-1} className='menuConvenios_svg_comp__cls-9' rx={3} />
			</g>
			<g id='menuConvenios_svg__Grupo_737' data-name='Grupo 737' transform='translate(3.861 11.873)'>
				<g id='menuConvenios_svg__Grupo_689' data-name='Grupo 689' clipPath='url(#menuConvenios_svg__clip-path)'>
					<g id='menuConvenios_svg__Grupo_688' data-name='Grupo 688' transform='translate(-579.807 -27.901)'>
						<g id='menuConvenios_svg__Grupo_687' className='menuConvenios_svg_comp__cls-4' data-name='Grupo 687'>
							<path
								id='menuConvenios_svg__Ret\xE2ngulo_386'
								d='M0 0h31.646v12.24H0z'
								className='menuConvenios_svg_comp__cls-1'
								data-name='Ret\xE2ngulo 386'
								transform='translate(574.462 22.558)'
							/>
						</g>
					</g>
				</g>
			</g>
			<g id='menuConvenios_svg__Grupo_738' data-name='Grupo 738' transform='translate(3.861 15.368)'>
				<g id='menuConvenios_svg__Grupo_693' data-name='Grupo 693' clipPath='url(#menuConvenios_svg__clip-path-3)'>
					<g id='menuConvenios_svg__Grupo_692' data-name='Grupo 692' transform='translate(-579.807 -31.396)'>
						<g id='menuConvenios_svg__Grupo_691' className='menuConvenios_svg_comp__cls-4' data-name='Grupo 691'>
							<path
								id='menuConvenios_svg__Ret\xE2ngulo_388'
								d='M0 0h19.233v12.239H0z'
								className='menuConvenios_svg_comp__cls-1'
								data-name='Ret\xE2ngulo 388'
								transform='translate(574.462 26.055)'
							/>
						</g>
					</g>
				</g>
			</g>
			<g id='menuConvenios_svg__Grupo_739' data-name='Grupo 739' transform='translate(14.542 15.368)'>
				<g id='menuConvenios_svg__Grupo_697' data-name='Grupo 697' clipPath='url(#menuConvenios_svg__clip-path-5)'>
					<g id='menuConvenios_svg__Grupo_696' data-name='Grupo 696' transform='translate(-590.488 -31.396)'>
						<g id='menuConvenios_svg__Grupo_695' className='menuConvenios_svg_comp__cls-4' data-name='Grupo 695'>
							<path
								id='menuConvenios_svg__Ret\xE2ngulo_390'
								d='M0 0h20.961v12.239H0z'
								className='menuConvenios_svg_comp__cls-1'
								data-name='Ret\xE2ngulo 390'
								transform='translate(585.147 26.055)'
							/>
						</g>
					</g>
				</g>
			</g>
			<g id='menuConvenios_svg__Grupo_741' data-name='Grupo 741' transform='translate(14.542 4.156)'>
				<g id='menuConvenios_svg__Grupo_705' data-name='Grupo 705' clipPath='url(#menuConvenios_svg__clip-path-7)'>
					<g id='menuConvenios_svg__Grupo_704' data-name='Grupo 704' transform='translate(-590.488 -20.184)'>
						<g id='menuConvenios_svg__Grupo_703' className='menuConvenios_svg_comp__cls-4' data-name='Grupo 703'>
							<path
								id='menuConvenios_svg__Ret\xE2ngulo_395'
								d='M0 0h19.234v12.239H0z'
								className='menuConvenios_svg_comp__cls-1'
								data-name='Ret\xE2ngulo 395'
								transform='translate(585.147 14.841)'
							/>
						</g>
					</g>
				</g>
			</g>
			<g
				id='menuConvenios_svg__Ret\xE2ngulo_408'
				className='menuConvenios_svg_comp__cls-2'
				data-name='Ret\xE2ngulo 408'
				transform='translate(3.473 3.472)'
			>
				<rect width={8.815} height={6.411} className='menuConvenios_svg_comp__cls-8' rx={2} />
				<rect width={6.815} height={4.411} x={1} y={1} className='menuConvenios_svg_comp__cls-9' rx={1} />
			</g>
		</g>
	</svg>
)

export default SvgMenuConvenios
