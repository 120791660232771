import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'

import { Provider } from 'reakit/Provider'
// import * as system from 'reakit-system-bootstrap'
import Blackbar from './Blackbar'
import Whitebar from './Whitebar'
import AllCategories from './AllCategories'
import styled from 'styled-components'
import Mobile from './Mobile'
import { setClientMedic } from '@monorepo-amais/commons/utils/sessionControl'

const DesktopWrapper = styled.div`
	@media (max-width: 1023px) {
		display: none;
	}

	position: relative;
`

const MobileWrapper = styled.div`
	@media (min-width: 1024px) {
		display: none;
	}

	display: block;
`

const FixedWrapper = styled.div`
	position: fixed;
	z-index: 2;
	width: 100vw;
`

/**
 * Componente da Navbar, mobile e desktop.
 * a prop isClientFromLayout contempla a chave de troca do menu (cliente ou médico), vinda direto do Layout.
 * Esse caso acontece quando a página '/medicos' é aberta direto do navegador.
 *
 * toggleBackdrop: abre o menu de todas as categorias (não utilizada mais no novo layout)
 *
 * isScrolled: verifica se houve o scroll na página
 *
 * theme: tema do site para ser utilizado no react-select e outros componentes não-styled
 *
 * setNavbarRef: função de mudança de estado vindo do layout, cuja função é pegar a ref da navbar,
 * pegar a altura deste e determinar como padding-top do container principal.
 *
 * regionais: informações das regionais vindas do cosmic
 *
 * logoPositivo: Componente SVG a ser mostrado na barra branca
 *
 * logoNegativo: Componente SVG a ser mostrado na barra preta
 */
export default function Navbar({
	menuItens,
	isClientFromLayout,
	regionais,
	setNavbarRef,
	logoPositivo,
	logoNegativo,
	theme,
	linkCliente,
	linkMedico,
	contatos,
	contatosAgendar
}) {
	if (!regionais) console.warn('campo regionais vazio no Header. Informações não serão mostradas.')

	const [isExpanded, setIsExpanded] = useState(false)
	const [isScrolled, setIsScrolled] = useState(false)

	const [isClient, setIsClient] = useState(true)

	const [pageYOffset, setPageYOffset] = useState(0)

	useEffect(() => {
		setIsClient(isClientFromLayout)
	}, [isClientFromLayout])

	const scrollPage = useCallback(() => {
		// Se o usuário está scrollando a página e da um scrollzinho pra cima
		// a barra branca deve aparecer novamente, ou seja, isScrolled deve ser false

		if (typeof window !== 'undefined') {
			if (window.pageYOffset < pageYOffset) setIsScrolled(false)
			else setIsScrolled(window.pageYOffset > 50)

			setPageYOffset(window.pageYOffset)

			window.removeEventListener('scroll', scrollPage, { passive: true })

			if (window.pageYOffset === 0) setIsScrolled(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [typeof window !== 'undefined' && window.window.pageYOffset, pageYOffset])

	// Efeito para "escutar" o scroll da página
	useEffect(() => {
		// let ret = null
		if (typeof window !== 'undefined') {
			window.addEventListener('scroll', scrollPage, { passive: true })
		}

		return () => window.removeEventListener('scroll', scrollPage, { passive: true })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageYOffset])

	// Ref da parte que expande da Navbar - Não utilizado mais no layout
	// const expandedRef = useRef()

	/**
	 * Efeito de backdrop
	 * Verifica se o usuário clicou fora da Navbar, o que significa que o backdrop deve ser retirado.
	 * Não utilizado mais no layout.
	 */
	// const handleClickOutside = e => {
	// 	if (expandedRef.current.contains(e.target)) {
	// 		// inside click

	// 		return
	// 	} else {
	// 		// outside click
	// 		setIsExpanded(false)
	// 		// setPageOpacity(1)
	// 		toggleBackdrop()
	// 	}
	// }

	// Efeito que faz o "bind" dos clicks na navbar - não utilizado mais no novo layout.
	// useEffect(() => {
	// 	if (isExpanded) {
	// 		document.addEventListener('mousedown', handleClickOutside)
	// 	} else {
	// 		document.removeEventListener('mousedown', handleClickOutside)
	// 	}

	// 	// toggleBackdrop()
	// 	return () => document.removeEventListener('mousedown', handleClickOutside)
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [isExpanded])

	useEffect(() => {
		if (navbarRef.current) {
															setNavbarRef(navbarRef)
														}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const navbarRef = useRef()

	const toggleExpanded = useCallback(() => setIsExpanded(!isExpanded), [isExpanded]) // || toggleBackdrop()

	const toggleType = useCallback(() => {
		if (typeof window !== 'undefined') {
			setClientMedic(isClient ? 'medico' : 'cliente')
		}
		setIsClient(!isClient)
	}, [isClient])

	const typeParsed = useMemo(() => (isClient ? 'cliente' : 'medico'), [isClient])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const urlResults = useMemo(() => (isClient ? linkCliente : linkMedico), [isClient])

	return (
		<Provider>
			<FixedWrapper ref={navbarRef}>
				<DesktopWrapper>
					<Whitebar
						show={!isScrolled}
						toggleType={toggleType}
						type={typeParsed}
						logo={logoPositivo}
						contatos={contatos}
						contatosAgendar={contatosAgendar}
						isClient={isClient}
					/>
					<Blackbar
						showLogo={isScrolled}
						regionais={regionais}
						logo={logoNegativo}
						menuItens={menuItens(urlResults)}
						isClient={isClient}
						theme={theme}
					/>
					<AllCategories show={isExpanded} type={typeParsed} urlResults={urlResults} />
				</DesktopWrapper>
				<MobileWrapper>
					<Mobile
						toggleType={toggleType}
						type={typeParsed}
						showExtension={isExpanded}
						toggle={toggleExpanded}
						regionais={regionais}
						logo={logoPositivo}
						theme={theme}
						contatos={contatos}
						contatosAgendar={contatosAgendar}
						menuItens={menuItens(urlResults)}
						isClient={isClient}
					/>
				</MobileWrapper>
			</FixedWrapper>
		</Provider>
	)
}
