/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useEffect } from 'react'

import Select, { components } from 'react-select'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import useStateWithLocalStorage from '../SelectState/useStateWithLocalStorage'
import MenuPin from '@monorepo-amais/commons/components/Icons/MenuPin'
import { getHrefByRegion, getCurrentRegion } from '../../utils/region'
import PinSelect from '@monorepo-amais/commons/components/Icons/PinSelect'

const selectStyles = {
	container: (base, props) => ({
		...base,
		width: '100%'
	}),
	indicatorSeparator: (base, props) => ({
		...base,
		background: 'none'
	}),
	dropdownIndicator: (base, props) => ({
		...base,
		// marginBottom: '-12px',
		svg: {
			fill: '#009fde',
			width: '15px',
			height: '15px'
		}
	}),
	control: (base, props) => ({
		...base,
		color: '#009fde', // TODO: trocar para o theme
		border: 'none',
		// width: '129px',
		boxShadow: 'none',
		borderRight: '2px solid #edeff4',
		borderRadius: '17px',
		'&:hover': {
			borderColor: '#e2e2e2'
		}
	}),
	menu: base => ({
		...base,
		marginTop: 0,
		textAlign: 'left',
		width: '100%',
		wordWrap: 'break-word',
		overflow: 'hidden'
	}),
	menuList: base => ({
		...base,
		padding: 0
	}),
	singleValue: base => ({
		...base,
		color: '#009fde',
		fontSize: '12px',
		fontWeight: 'bold',
		lineHeight: '19px',
		padding: '0'
	}),
	placeholder: base => ({
		...base,
		color: '#009fde',
		fontSize: '14px',
		lineHeight: '19px'
	}),
	option: (base, state) => ({
		...base,
		fontSize: '14px',
		lineHeight: '19px',
		backgroundColor: state.isSelected ? '#009fde' : '#ffffff',
		color: state.isSelected ? '#ffffff' : '#666666',
		'&:active': {
			backgroundColor: state.isSelected ? '#009fde' : '#ffffff',
			color: state.isSelected ? '#ffffff' : '#666666'
		},
		'&:hover': {
			backgroundColor: '#009fde',
			color: '#ffffff'
		}
	})
}

const SelectFlex = styled.div`
	display: flex;
	flex-direction: column;
	// height: 100%;
	margin-left: ${props => (props.marginLeft ? '-10px' : '-19px')};

	.icon {
		margin-left: 15px;

		@-webkit-keyframes fadeInFromNone {
			0% {
				display: none;
				opacity: 0;
			}

			1% {
				display: block;
				opacity: 0;
			}

			100% {
				display: block;
				opacity: 1;
			}
		}

		@-moz-keyframes fadeInFromNone {
			0% {
				display: none;
				opacity: 0;
			}

			1% {
				display: block;
				opacity: 0;
			}

			100% {
				display: block;
				opacity: 1;
			}
		}

		@-o-keyframes fadeInFromNone {
			0% {
				display: none;
				opacity: 0;
			}

			1% {
				display: block;
				opacity: 0;
			}

			100% {
				display: block;
				opacity: 1;
			}
		}

		@keyframes fadeInFromNone {
			0% {
				display: none;
				opacity: 0;
			}

			1% {
				display: block;
				opacity: 0;
			}

			100% {
				display: block;
				opacity: 1;
			}
		}

		-webkit-animation: fadeInFromNone 2s ease-out infinite;
		-moz-animation: fadeInFromNone 2s ease-out infinite;
		-o-animation: fadeInFromNone 2s ease-out infinite;
		animation: fadeInFromNone 2s ease-out infinite;
	}
`

const Telephone = styled.p`
	margin-left: 5px;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	margin-left: 5px;
	color: ${theme('colors.primary')};
	z-index: 1;
`

const ControlComponent = props => (
	<SelectFlex>
		<components.Control {...props}>
			<span className='icon'>
				<PinSelect />
			</span>{' '}
			{props.children}
		</components.Control>
	</SelectFlex>
)

/**
 * react-select dos estados para Desktop.
 * TODO: aria labels
 */
export default function SelectDesktop({ regionais, currentOption }) {
	const [option, setOption] = useStateWithLocalStorage() //option from LocalStorage

	// TODO: LINK DA BAHIA DINAMICO, VINDO DO COSMIC
	const handleSelectChange = useCallback(({ value, label }) => {
		getHrefByRegion(window.location.origin, value).then(
			({ target, href }) => {
				// if (target === '_self') setOption({ value, label })
				if (typeof window !== 'undefined') {
					// verifica se possui uma regional na url
					const region = window.location.pathname.match(/\/([a-z]{2})/)
					if (region) {
						// evita redirecionar para a mesma regional
						if (!href.includes(region[0])) window.open(href, target)
					} else window.open(href, target)
				}
			},
			[option]
		)
	})

	const options = useMemo(
		() =>
			regionais.metadata.telefones
				.filter(x => x.sigla !== 'ba' && x.sigla !== 'rj' && x.sigla !== 'df')
				.map(regiao => ({
					value: regiao.sigla,
					label: regiao.estado,
					tel: regiao.telefone,
					apareceuModal: false
				})),
		[regionais]
	)

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const estado = getCurrentRegion()
			const current = options[options.map(option => option.value).indexOf(estado)]
			const selection = options.length > 1 ? current || options[0] : 'Telefone'
			setOption(selection)
		}
	}, [options, setOption])

	// TODO: react-select aceita um tema

	return (
		<>
			{options.length > 1 ? (
				<Select
					aria-label='Escolher região'
					options={options}
					styles={selectStyles}
					components={{ Control: ControlComponent }}
					onChange={handleSelectChange}
					value={option}
					id='select-region-div'
					inputId='select-region-input'
					icon={<MenuPin />}
					placeholder='Selecione'
				/>
			) : (
				<SelectFlex marginLeft='-10px'>
					<Telephone id='label-telephone'>{currentOption.link ? 'Whatsapp' : 'Telefone'}</Telephone>
				</SelectFlex>
			)}
		</>
	)
}
