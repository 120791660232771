// import React from 'react'
import styled from 'styled-components'

export default styled.div`
	display: flex;
	flex-wrap: wrap;
	margin: ${props => props.m};
	justify-content: ${({ justify }) => justify};
	align-items: ${({ align }) => align};
	height: ${({ height }) => (height ? height : '100%')};
	padding: ${({ p }) => p};

	@media (max-width: 375px) {
		margin: 0 0 20px 0;
	}
`
