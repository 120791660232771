import React from 'react'
import Select from 'react-select'
import { Label } from './Input'
import { FormGroup } from '../Blocks'
import styled from 'styled-components'

const CustomLabel = styled(Label)`
	line-height: ${({ workaroundLabelValue }) => (workaroundLabelValue ? '20px' : '80px')};
	height: ${({ workaroundLabelValue }) => (workaroundLabelValue ? '20px' : '')};
	font-size: ${({ workaroundLabelValue, labelFontSize }) => (workaroundLabelValue ? '11px' : labelFontSize)};
	left: ${({ labelLeft }) => (labelLeft ? labelLeft : '')};
`

const SelectInput = styled(Select)`
  font-size: ${props => (props.fontSize ? props.fontSize : '12px')};
	color: ${props => (props.color ? props.color : '')};
	height: 24px;
	border: none;
	border-bottom: ${props => (props.borderBottom ? props.borderBottom : '')};;
	background: transparent;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: left;
	position: relative;
	display: block;
	width: 100%;
	margin: ${({ margin }) => margin || '21px auto auto auto'};
	background: linear-gradient(to top, ${props => props.theme.colors.primary} 50%, ${props =>
	props.theme.colors.primary} 50%);
	background-position: left bottom;
	background-size: 0 1px;
	background-repeat: no-repeat;
	padding: ${props => (props.padding ? props.padding : '0px 10px')};
  transition: all 0.2s ease-in-out;
  
  &:focus-within {
		background-position: left bottom;
    background-size: 100% 1px;
	}

  // &:not([value=''])+${CustomLabel}, 
	// &:valid+${CustomLabel}, 
	&:focus-within+${CustomLabel} {
		margin-top: ${props => (props.marginTop ? props.marginTop : '')};
		height: 20px;
		line-height: 20px;
		font-size: 11px;
	}

	&:focus-within &::-webkit-input-placeholder {
		color: transparent;
		font-size: 0;
	}

	&:focus-within &:-moz-placeholder {
		color: transparent;
		font-size: 0;
	}

	&:focus-within &::-moz-placeholder {
		color: transparent;
		font-size: 0;
	}

	&:focus-within &:-ms-input-placeholder {
		color: transparent;
		font-size: 0;
	}

	outline: none;
	width: 100%;

	@media (max-width: 767.98px) {
		background:  ${props => (props.inputBackground ? props.inputBackground : '#f1f1f1')};
		// font-size: calc(0.3em + 1vw);
		font-size: 14px;
	}

	@media (min-width: 375px) {
		padding: 0;
	}
`

const Required = styled.span`
	color: ${props => props.theme.colors.primary};
`

const selectStyles = theme => ({
	indicatorSeparator: base => ({
		...base,
		background: 'none'
	}),
	dropdownIndicator: base => ({
		...base,
		svg: {
			fill: theme.colors.primary
		}
	}),
	control: base => ({
		...base,
		border: 'none',
		backgroundColor: theme.colors.white,
		'border-bottom': `solid 1px ${theme.colors.graye2}`,
		boxShadow: 'none',
		'&:hover': {
			borderColor: theme.colors.graye2
		}
	}),
	menu: base => ({
		...base,
		marginTop: 0,
		wordWrap: 'break-word',
		overflow: 'hidden'
	}),
	menuList: base => ({
		...base,
		padding: 0
	}),
	singleValue: base => ({
		...base,
		color: theme.colors.gray66,
		fontSize: '12px',
		fontWeight: 'bold',
		lineHeight: '19px'
	}),
	placeholder: base => ({
		...base,
		color: theme.colors.gray66,
		fontSize: '14px',
		lineHeight: '19px'
	}),
	option: (base, state) => ({
		...base,
		fontSize: '14px',
		lineHeight: '19px',
		backgroundColor: state.isSelected ? theme.colors.primary : theme.colors.white,
		color: state.isSelected ? theme.colors.white : theme.colors.gray66,
		':active': {
			backgroundColor: state.isSelected ? theme.colors.primary : theme.colors.white,
			color: state.isSelected ? theme.colors.white : theme.colors.gray66
		},
		'&:hover': {
			backgroundColor: theme.colors.primary,
			color: theme.colors.white
		}
	})
})

/**
 * Componente de seleção baseado no react-select
 * @param {boolean} required - adiciona o asterisco de obrigatoriedade
 * @param {string} id -
 * @param {string} name -
 * @param {string} placeholder -
 * @param {string} label - se enviada, o placeholder é ignorado e a label flutuante surge
 * @param {{value: string | number | null, label: string | number}} selected - objeto que representa o valor selecionado
 * @param {(event) => {}} onChange - callback para capturar o evento de mudança
 * @param {{value: string | number | null, label: string | number}[]} options - items para selecionar
 * @param {string} lineHeight - altura customizada da label
 * @param {string} fontSize - tamanho customizado da label
 * @param {string} color - cor customizada da label
 */
export default ({
	required,
	id,
	name,
	selected,
	placeholder,
	onChange,
	label,
	options,
	lineHeight,
	fontSize,
	color,
	labelLeft,
	labelFont,
	inputBackground,
	selectWidth,
	marginRight,
	theme
}) => (
	<FormGroup selectWidth={selectWidth} marginRight={marginRight}>
		<SelectInput
			required={true}
			styles={selectStyles(theme)}
			value={selected}
			options={options}
			onChange={onChange}
			placeholder={!label ? placeholder || '' : ''}
			id={id}
			name={name}
			inputBackground={inputBackground}
		/>
		{label && (
			<CustomLabel
				workaroundLabelValue={selected}
				htmlFor={id || name}
				className='label-float'
				lineHeight={lineHeight}
				fontSize={fontSize}
				color={color}
				labelLeft={labelLeft}
				labelFontSize={labelFont}
			>
				{label}
				{required === true ? <Required>*</Required> : null}
			</CustomLabel>
		)}
	</FormGroup>
)
