import React from 'react'

const SvgMenuExames = ({ isClient, theme, ...props }) => (
	<svg width={28.844} height={21.37} {...props}>
		<defs>
			<clipPath id='menuExames_svg__clip-path'>
				<path
					id='menuExames_svg__Caminho_90'
					d='M358.847 113.121h-2.613a1 1 0 00-.893.547l-1.046 2.082-2.21-6.852a1 1 0 00-1.838-.143l-1.87 3.7h-2a1 1 0 100 2h2.613a1 1 0 00.892-.552l1.047-2.077 2.21 6.847a.993.993 0 00.871.691l.08.005a1 1 0 00.887-.552l1.87-3.7h2a1 1 0 000-2zm-9.381 3.756a26.917 26.917 0 01-5.206 2.613c-1.844-.674-11.26-4.49-11.26-11.622a5.7 5.7 0 0110.433-3.166 1.036 1.036 0 001.657 0 5.7 5.7 0 0110.433 3.166 8.1 8.1 0 01-.292 2.141 1 1 0 101.923.531 10.1 10.1 0 00.361-2.672 7.691 7.691 0 00-13.253-5.312A7.695 7.695 0 00331 107.868c0 9.3 12.419 13.455 12.951 13.631a.985.985 0 00.308.048 1.01 1.01 0 00.308-.048 28.239 28.239 0 005.992-2.948 1 1 0 10-1.094-1.673z'
					className='menuExames_svg_comp__cls-1'
					data-name='Caminho 90'
					transform='translate(-331.001 -100.176)'
				/>
			</clipPath>
			<clipPath id='menuExames_svg__clip-path-2'>
				<path
					id='menuExames_svg__Ret\xE2ngulo_368'
					d='M0 0h1538.88v85.493H0z'
					className='menuExames_svg_comp__cls-1'
					data-name='Ret\xE2ngulo 368'
				/>
			</clipPath>
			<style>{`.menuExames_svg_comp__cls-1{fill:${
				theme ? (!isClient ? theme.colors.white : theme.colors.primary) : ''
			}}`}</style>
		</defs>
		<g id='menuExames_svg__Grupo_653' data-name='Grupo 653'>
			<g id='menuExames_svg__Grupo_652' data-name='Grupo 652' clipPath='url(#menuExames_svg__clip-path)'>
				<g id='menuExames_svg__Grupo_651' data-name='Grupo 651' transform='translate(-487.311 -16.032)'>
					<g id='menuExames_svg__Grupo_650' data-name='Grupo 650' clipPath='url(#menuExames_svg__clip-path-2)'>
						<path
							id='menuExames_svg__Ret\xE2ngulo_367'
							d='M0 0h39.532v32.06H0z'
							className='menuExames_svg_comp__cls-1'
							data-name='Ret\xE2ngulo 367'
							transform='translate(481.968 10.686)'
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
)

export default SvgMenuExames
