import React from 'react'

// navegadores antigos/safari/ios nao suportam o formato webp
// entao realiza-se uma checagem para utilizar ou nao essa abordagem de melhoria
function checkCanUseWebP() {
	if (typeof window !== 'undefined') {
		const elem = document.createElement('canvas')

		if (elem.getContext && elem.getContext('2d')) {
			// was able or not to get WebP representation
			return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0
		}
	}
	// very old browser like IE 8, canvas not supported
	return false
}

export const canUseWebP = checkCanUseWebP()

/**
 * Wrapper para imagens usando o Imgix
 * @param src - src da imagem imgmix
 * @param alt - descrição da imagem para acessibilidade
 * @param w - largura (width) da imagem em px
 * @param props restante das props que será aplicada ao <img />
 */
export default function ImgixImg({ src, alt, w, h, ...props }) {
	if (canUseWebP) return <img src={`${src}?fm=webp&lossless=0&w=${w}&h=${h}`} {...props} alt={alt} />
	else return <img src={src} {...props} alt={alt} />
}
