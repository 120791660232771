import React from 'react'
import styled from 'styled-components'

const CloseButton = styled.button`
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;

	@media (max-width: 768px) {
		display: ${({ hideOnMobile }) => (hideOnMobile ? '' : 'none')};
	}
`

export default ({ onClick, children, hideOnMobile, ...props }) => (
	<CloseButton onClick={onClick} hideOnMobile={hideOnMobile} {...props}>
		{children}
	</CloseButton>
)
