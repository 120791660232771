import React, { useRef, useEffect, useState } from 'react'

import { FaAngleUp, FaFacebookF, FaInstagram, FaYoutube, FaTwitter } from 'react-icons/fa'

import styled, { css } from 'styled-components'
import { theme, prop } from 'styled-tools'

import { Link } from 'gatsby'

import FooterLogo from '../Icons/FooterLogo'

import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'
import getBrand from '../../utils/getBrand'

import ScheduleModal from '../ScheduleModal/scheduleModal'

const amaisSP =
	process.env.GATSBY_COSMIC_BUCKET === 'amaissp' || process.env.GATSBY_COSMIC_BUCKET === 'labs-amais' ? true : false

const FooterContent = styled.footer`
	background-color: ${theme('colors.black_fonts')};
	position: relative;
	padding: 0 10px 20px;

	display: flex;
	flex-direction: column;
	align-items: center;

	margin: 0 auto;
	padding: 0 10px;

	@media (max-width: 1024px) {
		align-items: stretch;
	}
`

const FooterScrollToTop = styled.span`
	background-color: #464646;
	color: #fff;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	width: 183px;
	height: 64px;
	font-size: 18px;
	font-weight: 900;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	cursor: pointer;
	transition: 0.2s all ease-in;
	&:hover {
		background-color: #3e3e3e;
	}

	@media (max-width: 767.98px) {
		display: none;
	}
`

const FooterGrid = styled.div`
	display: grid;
	grid-template-columns: 1.2fr 1fr 0.5fr;
	grid-template-areas: 'categorias contato redes';
	grid-gap: 40px;
	padding: 100px 0;
	width: 98%;
	max-width: 1360px;

	@media (max-width: 767.98px) {
		display: block;
		padding: 20px 15px;
		grid-template-columns: 1fr;
		grid-template-areas:
			'contato'
			'redes';
		padding: 20px 0 20px 0;
	}

	@media (min-width: 767.98px) and (max-width: 1024px) {
		grid-gap: 10px;
		grid-template-columns: 1fr;
		grid-template-areas:
			'contato'
			'redes';
		padding: 100px 0 20px 0;
	}
`

const FooterGridElement = styled.div`
	/* padding: 0 30px; */
	grid-area: ${prop('gridArea')};

	@media (max-width: 1024px) {
		padding: 0 15px;
		display: ${props => (props.gridArea === 'categorias' ? 'none' : 'block')};
	}

	/* @media (min-width: 767.98px) and (max-width: 1024px) {
		padding: 0;
	} */
`

const FooterH4 = styled.h4`
	color: ${theme('colors.white')};
	font-size: 20px;
	padding: 0 0 20px;
	margin: 0 0 10px 0;
	width: 100%;
	position: relative;
	font-weight: 600;
	&:after {
		content: '';
		width: 90%;
		height: 5px;
		background-color: ${theme('colors.primary')};
		position: absolute;
		bottom: 0;
		left: 0;
	}
	@media (max-width: 767.98px) {
		&:after {
			width: 100%;
		}
	}
`

const FooterH = styled.h4`
	color: #fff;
	font-size: 20px;
	font-weight: 600;
	width: 100%;
	margin: 0 0 20px 0;
`

const FooterUl = styled.ul`
	padding: 0 15px 20px 0;
	vertical-align: top;

	height: ${prop('h', '120px')};
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;

	/* no mobile ele força pra 3 colunas, aumentando o tamanho */
	// quantidade de colunas conforme parametro enviado (1 coluna por padrao)
	@media (max-width: 1440px) {
		flex-direction: row;
	}

	@media (max-width: 767.98px) {
		height: auto;
		display: grid;
		grid-template-columns: ${({ mobileCountColumns = 1 }) => new Array(mobileCountColumns).fill('auto').join(' ')};
	}
`

const FooterLi = styled.li`
	list-style: none;
	margin-bottom: 22px;
`
const FooterLiPhone = styled.li`
	list-style: none;
	margin-bottom: 15px;
`

const Flex = styled.div`
	display: flex;
	flex-direction: row;
`

const LinkCSS = css`
	text-decoration: none;
	color: #fff;
	font-size: 16px;
	margin-right: 15px;

	&:hover {
		color: #fff;
		span {
			border-color: ${theme('colors.primary_hover')};
			svg {
				color: ${theme('colors.primary_hover')};
			}
		}
	}
`

const ExternalLink = styled.a`
	${LinkCSS};
`

const InternalLink = styled(Link)`
	${LinkCSS};
`

const footerText = css`
	font-size: 16px;
	color: #fff;
	margin: 0 0 5px;
	&:hover {
		text-decoration: none;
		color: #fff;
	}
`

const FooterP = styled.p`
	${footerText};
`

const FooterPhone = styled.a`
	${footerText};
	text-decoration: none;
	font-weight: 900;
	font-size: 14px;
`
const FooterWhatsapp = styled.a`
	${footerText};
	font-size: 14px;
	text-decoration: none;
	font-weight: 900;
`

const FooterBottom = styled.div`
	text-align: center;
	padding: 0 30px 10px 0;

	@media (max-width: 767.98px) {
		text-align: left;
		padding: 15px;
	}
`

const FooterPolitics = styled.div`
	display: inline-block;
	color: #fff;
	font-size: 12px;

	@media (max-width: 767.98px) {
		font-size: 12px;
		line-height: 23px;
	}

	a {
		color: #fff;
		text-decoration: none;
		&:visited,
		&:focus {
			color: #fff;
		}
		&:hover {
			text-decoration: underline;
		}
	}
`

const CustomFaAngleUp = styled(FaAngleUp)`
	font-size: 23px;
`

const SpanSocial = styled.span`
	display: flex;
	background: none;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	align-items: center;
	justify-content: center;
	border: 1px solid ${theme('colors.primary')};
	transition: 0.2s all ease-in;
`

const customIcon = css`
	display: block;
	margin: 0 auto;
	color: ${theme('colors.primary')};
	font-size: 20px;
`

const CustomFaFacebookSquare = styled(FaFacebookF)`
	${customIcon}
`

const CustomFaTwitter = styled(FaTwitter)`
	${customIcon}
`

const CustomFaInstagram = styled(FaInstagram)`
	${customIcon}
`

const CustomFaYoutube = styled(FaYoutube)`
	${customIcon}
`

const FooterApp = styled.a`
	margin-right: 20px;
	text-decoration: none;
	img {
		width: 130px;
	}
	&:last-child {
		margin-right: 0;
	}
`

const FooterDivSocial = styled.div`
	margin-bottom: 40px;
	@media (max-width: 767.98px) {
		padding-left: 0;
		&:last-child {
			margin-bottom: 0;
		}
	}
`

const FooterInformation = styled.div`
	display: flex;
	align-items: center;
	font-weight: 300;
	color: ${theme('colors.white')};
	font-size: 12px;
	flex-direction: column;
	margin-top: 10px;
	margin-bottom: 5px;

	span {
		text-align: center;
	}
`

const FooterDivider = styled.hr`
	border-color: #999;
	width: 100%;
	max-width: 1360px;
	border-width: 0.3px;
`

const FooterDivCTAAndToTop = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 24px;
	width: 100%;
`

const GridTwoColumns = styled.div`
	display: flex;
`

const CTAButton = styled.h2`
	margin: 0;
	.btn-schedule {
		background-color: #009FDE;
		//margin-right: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		color: white;
		font-weight: bold;
		height: 64px;
		border-radius: 0px 0px 6px 6px;
		width: ${amaisSP ? '260px' : '180px'};
		padding: 1rem 2rem;

		&:not([disabled]):hover {
			background-color: #009fde;
			border-color: #009fde;
			opacity: 0.8;
		}

		&:not([disabled]):active {
			background-color: #009fde;
			border-color: #009fde;
		}

		margin-left: 2% !important;

		@media (max-width: 1290px) {
			width: ${amaisSP ? '250px' : '170px'};
			margin-left: 1% !important;
		}

		@media (max-width: 1250px) {
			width: ${amaisSP ? '250px' : '160px'};
			margin-left: 1% !important;
		}

		@media (max-width: 1100px) {
			width: ${amaisSP ? '245px' : '150px'};
			margin-left: 1% !important;
		}

		@media (max-width: 1050px) {
			width: ${amaisSP ? '245px' : '140px'};
			margin-left: 1% !important;
		}

		@media (max-width: 1024px) {
			display: none;
		}
	}
`

const injectFooter = footer => {
	if (!footer.firstElementChild.innerHTML.includes('Atualizado em')) {
		footer.firstElementChild.innerHTML =
			footer.firstElementChild.innerHTML + `Atualizado em ${new Date().toLocaleDateString()}`
	}
}

/**
 * Componente do footer, utilizado em todos os componentes através do Layout.js
 * @param { metadata } footer Objeto das informações estáticas vindas do Cosmic
 */
function Footer({ metadata, urlCareers, aboutText, contatos, contatosAgendar }) {
	const footerEl = useRef(null)
	const [showSchedule, setShowSchedule] = useState(false)

	useEffect(() => {
		injectFooter(footerEl.current)
	}, [])

	if (!metadata) console.warn('campo metadata vazio no Footer. Informações não serão mostradas.')
	const { responsavel_tecnico, redes_sociais, telefones_footer = [], politicas, loja } = metadata

	return (
		<FooterContent id='footer-container'>
			<ScheduleModal
				showSchedule={showSchedule}
				setShowSchedule={setShowSchedule}
				contatos={contatos}
				contatosAgendar={contatosAgendar}
			></ScheduleModal>
			<FooterDivCTAAndToTop>
				<FooterScrollToTop
					id='button-scroll-top'
					onClick={() =>
						window.scrollTo({
							behavior: 'smooth',
							top: 0
						})
					}
				>
					<CustomFaAngleUp />
					Ir para o topo
				</FooterScrollToTop>
				{process.env.GATSBY_COSMIC_BUCKET === 'amaissp' ? (
					<CTAButton
						id='button-scheduling-exams'
						onClick={() => window.open('https://agendamento.amaissaude.com.br', '_self')}
					>
						<button className='btn-schedule'>Agendar exames e vacinas</button>
					</CTAButton>
				) : process.env.GATSBY_COSMIC_BUCKET === 'labs-amais' ? (
					<CTAButton
						id='button-scheduling-exams'
						onClick={() => window.open('https://agendamento.labsamais.com.br/', '_self')}
					>
						<button className='btn-schedule'>Agendar exames e vacinas</button>
					</CTAButton>
				) : (
					<CTAButton id='button-scheduling-exams' onClick={() => setShowSchedule(true)}>
						<button className='btn-schedule'>Agendar</button>
					</CTAButton>
				)}
			</FooterDivCTAAndToTop>
			<FooterGrid id='footer-columns'>
				<FooterGridElement id='categories' gridArea='categorias'>
					<FooterH4>{aboutText}</FooterH4>
					<FooterUl>
						<FooterLi>
							<InternalLink to='/institucional/'>Institucional</InternalLink>
						</FooterLi>

						{process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'labs-amais' && (
							<FooterLi>
								<InternalLink to='/atendimento-domiciliar/'>Atendimento Domiciliar</InternalLink>
							</FooterLi>
						)}

						<FooterLi>
							<InternalLink to='/faq/'>FAQ</InternalLink>
						</FooterLi>

						{process.env.GATSBY_COSMIC_BUCKET.toLowerCase() !== 'amaispi' ? (
							<FooterLi>
								<ExternalLink href={getBrand().ouvidoria}>Ouvidoria</ExternalLink>
								{/* <InternalLink to='/fale-conosco/'>Ouvidoria</InternalLink> */}
							</FooterLi>
						) : (
							<FooterLi>
								{/* <ExternalLink href={getBrand().ouvidoria}>Ouvidoria</ExternalLink> */}
								<InternalLink to='/fale-conosco/'>Ouvidoria</InternalLink>
							</FooterLi>
						)}

						<FooterLi>
							{/* <InternalLink to='/portal-de-privacidade/'>Portal de Privacidade</InternalLink> */}
							<ExternalLink
								href={'https://privacyportal.onetrust.com/policies/26edb1e8-78ab-465b-9129-d0d9cf1f833e?context=eyJkc1BvcnRhbElkIjoiMGFjZGE4ODgtNDNlZS00OTdjLTk4MmQtMTExMmE2Y2ZhM2NkIn0%3D'}
								target='_blank'
							>
								Portal de Privacidade
							</ExternalLink>
						</FooterLi>

						<FooterLi>
							<InternalLink to='/direitos-dos-pacientes/'>Direitos e deveres dos pacientes</InternalLink>
						</FooterLi>

						<FooterLi>
							<ExternalLink href={urlCareers} target='_blank'>
								Trabalhe conosco
							</ExternalLink>
						</FooterLi>
					</FooterUl>
					<FooterLogo />
				</FooterGridElement>
				<FooterGridElement id='contact' gridArea='contato'>
					<FooterH4>Contato</FooterH4>
					{/* 2 colunas na versao mobile.
					TODO definir regra para 1 ou 2 colunas (atual > 3) */}
					<FooterUl h='240px' mobileCountColumns={telefones_footer.length > 3 ? 2 : 1}>
						{telefones_footer.map(data => (
							<FooterLiPhone key={`contact-phone-${data.telefone}`}>
								{data.link_whatsapp ? (
									<>
										<FooterP>{data.estado} &nbsp;&nbsp;</FooterP>
										<FooterWhatsapp href={data.link_whatsapp} target='_blank'>
											{data.whatsapp_telefone || data.telefone} &nbsp;&nbsp;
										</FooterWhatsapp>
									</>
								) : (
									<>
										<FooterP>{data.estado} &nbsp;&nbsp;</FooterP>
										<FooterPhone href={data.link_whatsapp ? data.link_whatsapp : `tel:${data.telefone}`}>
											{data.telefone} &nbsp;&nbsp;
										</FooterPhone>
									</>
								)}
							</FooterLiPhone>
						))}
					</FooterUl>
				</FooterGridElement>

				<FooterGridElement id='network' gridArea='redes'>
					<FooterDivSocial>
						<FooterH>Redes Sociais</FooterH>
						<Flex>
							{redes_sociais &&
								redes_sociais.map((rede, i) => {
									// verificar qual icone será renderizado
									let icon
									switch (rede.nome) {
										case 'facebook':
											icon = <CustomFaFacebookSquare />
											break
										case 'twitter':
											icon = <CustomFaTwitter />
											break
										case 'instagram':
											icon = <CustomFaInstagram />
											break
										case 'youtube':
											icon = <CustomFaYoutube />
											break
										default:
											break
									}
									return (
										<ExternalLink id={`network-link-${i}`} key={i} href={rede.link} target='_blank'>
											<SpanSocial>{icon}</SpanSocial>
										</ExternalLink>
									)
								})}
						</Flex>
					</FooterDivSocial>
					{loja &&
						process.env.COSMIC_BUCKET !== 'amaispi' &&
						((loja.android.imagem.imgix_url && loja.android.link) ||
							(loja.apple.imagem.imgix_url && loja.apple.link)) && (
							<FooterDivSocial>
								<FooterH>Baixe o aplicativo</FooterH>
								<GridTwoColumns>
									<FooterApp id='store-apple' href={loja.apple.link} target='_blank'>
										<img src={imgixUrlOptimizerAuto(loja.apple.imagem.imgix_url)} alt='link para a loja da apple'></img>
									</FooterApp>
									<FooterApp id='store-google' href={loja.android.link} target='_blank'>
										<img
											src={imgixUrlOptimizerAuto(loja.android.imagem.imgix_url)}
											alt='link para a loja do android'
										></img>
									</FooterApp>
								</GridTwoColumns>
							</FooterDivSocial>
						)}
				</FooterGridElement>
			</FooterGrid>
			<FooterDivider />
			<FooterBottom>
				<FooterInformation>
					<span>Fleury S.A. | CNPJ: 60.840.055/0001-31</span>
					<span>Av. Santo Amaro, 4584, CEP 04701-200 - São Paulo, SP</span>
					<span>Responsável técnico: {responsavel_tecnico}</span>
				</FooterInformation>
				<FooterPolitics ref={footerEl} dangerouslySetInnerHTML={{ __html: politicas }} />
			</FooterBottom>
		</FooterContent>
	)
}

export default Footer
