import axios from 'axios'

export const getStateNameByBucketName = () =>
	process.env.COSMIC_BUCKET === 'diagnoson'
		? 'da Diagnoson a+'
		: process.env.COSMIC_BUCKET === 'labs-amais'
			? 'do Labs a+'
			: process.env.COSMIC_BUCKET === 'irn'
				? 'do Instituto de Radiologia'
				: 'da a+ Medicina Diagnóstica'

const validRegions = ['sp', 'pr', 'pe', 'df']
export const usingRegion = region => validRegions.includes(region, 0)

export const getCurrentRegion = () => window.location?.pathname?.slice(1, 3)

const getUrlAnotherRegion = (region, path) => {
	const regionRegex = /\/([a-z]{2})\//

	// Se não há região na URL, retorna uma nova url com a regiao passada por args
	if (!regionRegex.test(window.location.pathname)) {
		return `${window.location.origin}/${region}/${path ? path + '/' : ''}`
	}

	return `${window.location.origin}${window.location.pathname.replace(regionRegex, `/${region}/`)}`
}

export const testPageStatus = async url => {
	try {
		const response = await axios.head(url)
		return response.status >= 200 && response.status < 300
	} catch (e) {
		return false
	}
}

const regions = (region, origin, path) => {
	const rs = {
		[region]: region ? `${origin}/${region}/${path ? path : ''}` : '',
		ba: 'https://www.diagnosonamais.com.br/',
		rj: 'https://www.labsamais.com.br/'
	}
	return rs[region]
}

const target = region => {
	// caso seja uma marca diferente, sobrescreve
	const ts = {
		[region]: '_self',
		ba: '_blank',
		rj: '_blank'
	}
	return ts[region]
}

const pathNames = region => {
	// adiciona o path completo, caso seja ba, que nao e index.html, sobrescreve para o pages/default
	const paths = { [region]: 'index.html', ba: 'http://www.diagnosonamais.com.br/', rj: 'http://www.labsamais.com.br/' }
	return paths[region]
}

export const getHrefByRegion = async (origin, region, pathname) => {
	const path =
		origin === 'http://localhost:8000' ? pathname?.slice(1, pathname.length) : pathname?.slice(4, pathname.length)
	const hrefByRegion = { target: '', href: '' }
	const urlInAnotherRegion = getUrlAnotherRegion(region, path)

	hrefByRegion.target = target(region)
	// remove o http{s} para certificar de colocar antes do retorno e assim garantir a troca completa da url
	hrefByRegion.href = regions(region, origin, path)

	// index.html por conta da aws dar erro se as paginas nao tiverem isso no fim. O erro que dara para
	// as outras regionais sao aceitaveis para o teste, mas a aplicacao ira para a home com o erro
	const completeUrl = `https://${(urlInAnotherRegion + '/index.html')
		.replace(/\/\//g, '/')
		.replace('https:/', '')
		.replace('http:/', '')}`

	const pageExists = await testPageStatus(completeUrl)

	if (pageExists) {
		// se a pagina existir na regiao selecionada, mantem na url
		hrefByRegion.href = completeUrl
	} else {
		// senao vai para a home

		if (region !== 'rj' && region !== 'ba') {
			hrefByRegion.href = `${origin}/${region}/${pathNames(region)}`
		} else {
			hrefByRegion.href = regions(region)
		}
	}

	if (!hrefByRegion.href.includes('http://') && !hrefByRegion.href.includes('https://'))
		hrefByRegion.href = `https://${hrefByRegion.href}`

	return hrefByRegion
}

export const removeStateFromRegions = (regions, prop, state) => {
	return regions.filter(region => region[prop] !== state);
};