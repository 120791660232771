import React from 'react'

const SvgDiagnosonPositivo = props => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlnsXlink='http://www.w3.org/1999/xlink'
		viewBox='0 0 1080 211.76'
		width={171.302}
		height={54.876}
		{...props}
		name='diagnoson-positivo'
	>
		<defs>
			<style>
				{
					'.diagnoson-cls-1{fill:url(#gradient);}.diagnoson-cls-2{fill:url(#gradient2);}.diagnoson-cls-3{fill:url(#gradient3);}.diagnoson-cls-4{fill:url(#gradient4);}.diagnoson-cls-5{fill:url(#gradient5);}.diagnoson-cls-6{fill:url(#gradient7);}.diagnoson-cls-7{fill:#fff;}.diagnoson-cls-8{fill:#009ddf;}'
				}
			</style>
			<linearGradient id='gradient' x1='-0.3' y1='93.42' x2='761.44' y2='93.42' gradientUnits='userSpaceOnUse'>
				<stop offset='0' stopColor='#213565' />
				<stop offset='1' stopColor='#009ddf' />
			</linearGradient>
			<linearGradient id='gradient2' x1='-0.3' y1='90.49' x2='761.44' y2='90.49' xlinkHref='#gradient' />
			<linearGradient id='gradient3' x1='-0.3' y1='107.64' x2='761.44' y2='107.64' xlinkHref='#gradient' />
			<linearGradient id='gradient4' x1='-0.3' y1='123.7' x2='761.44' y2='123.7' xlinkHref='#gradient' />
			<linearGradient id='gradient5' x1='-0.3' y1='106.61' x2='761.44' y2='106.61' xlinkHref='#gradient' />
			<linearGradient id='gradient7' x1='-0.3' y1='107.42' x2='761.44' y2='107.42' xlinkHref='#gradient' />
		</defs>
		<g id='Camada_2' data-name='Camada 2'>
			<g id='Logo'>
				<path
					className='diagnoson-cls-1'
					d='M0,147.87V39H42.59C59,39,71.77,43.83,81.05,53.7s14,23,14,39.94c0,16.65-4.71,29.92-14.29,39.64s-22.4,14.59-39.05,14.59ZM21.81,127H42.74c9.43,0,16.65-3.25,21.81-9.58s7.81-14.45,7.81-24.17S69.7,75.81,64.55,69.47s-12.38-9.58-21.81-9.58H21.81Z'
				/>
				<path
					className='diagnoson-cls-2'
					d='M111.42,54.88c-4.87-4.86-4.87-13.41,0-18.12a13.25,13.25,0,0,1,18.27,0c4.86,4.71,4.86,13.26,0,18.12A13.25,13.25,0,0,1,111.42,54.88Zm19.45,93H110.09V67.56h20.78Z'
				/>
				<path
					className='diagnoson-cls-3'
					d='M209.86,67.56h19.75v80.31H209.86v-9.28a33.44,33.44,0,0,1-25.64,11.2c-11.5,0-21.08-4-28.59-12.09s-11.2-18.27-11.2-30.35,3.83-22.11,11.35-29.92c7.51-8,17.09-11.94,28.44-11.94,10,0,18.57,3.83,25.64,11.65Zm-6.19,23.72a20.93,20.93,0,0,0-16.06-6.78c-6.63,0-11.94,2.21-16.21,6.78a23.56,23.56,0,0,0-6.19,16.36,23.19,23.19,0,0,0,6.33,16.51,22.45,22.45,0,0,0,32.13,0,24.05,24.05,0,0,0,0-32.87Z'
				/>
				<path
					className='diagnoson-cls-4'
					d='M309.05,67.56H328.2v72.35c0,12.82-4.12,23.14-12.38,30.66s-19,11.34-32.12,11.34a61.23,61.23,0,0,1-36.84-11.64l8.1-15.62c9.29,6.34,18.72,9.43,28.15,9.43,15.91,0,25.05-9.28,25.05-22.25V137.7C302,144.63,293.72,148,283.4,148c-11.79,0-21.36-3.83-28.88-11.64s-11.2-17.54-11.2-29.48,4-21.81,11.79-29.62,17.54-11.79,29.18-11.79A32.34,32.34,0,0,1,309.05,76.4Zm-38.47,55.85a22.78,22.78,0,0,0,31.83,0,22.54,22.54,0,0,0,6.34-16.36c0-6.48-2.06-11.79-6.34-16.06a22.76,22.76,0,0,0-31.83,0c-4.27,4.27-6.33,9.58-6.33,16.06C264.25,113.68,266.31,119.14,270.58,123.41Z'
				/>
				<path
					className='diagnoson-cls-5'
					d='M346.33,147.87V67.56h20.34v11.2c4.27-7.81,13.85-13.41,25.2-13.41A31.26,31.26,0,0,1,414.12,74c6.05,5.75,9.14,14,9.14,24.76v49.07H402.48V104.4c0-12.82-6.78-19.9-17-19.9-11,0-18.42,8.4-18.42,20.34v43Z'
				/>
				<path
					className='diagnoson-cls-3'
					d='M448.17,77.72q12.16-12.37,30.95-12.37t30.95,12.37c8.1,8.11,12.08,18.13,12.08,29.92s-4,22-12.08,30.06-18.42,12.23-30.95,12.23-22.84-4.12-30.95-12.23-12.08-18.12-12.08-30.06S440.07,85.83,448.17,77.72Zm30.95,52.91A20.93,20.93,0,0,0,494.89,124a23.2,23.2,0,0,0,6.48-16.36,22.59,22.59,0,0,0-6.48-16.21,20.93,20.93,0,0,0-15.77-6.63,21.26,21.26,0,0,0-15.92,6.63,22.46,22.46,0,0,0-6.33,16.21A23.06,23.06,0,0,0,463.2,124,21.26,21.26,0,0,0,479.12,130.63Z'
				/>
				<path
					className='diagnoson-cls-6'
					d='M529.08,134.46l11.06-13.11c5.74,6.92,14.14,11.2,22.25,11.2,6.33,0,11.05-3.25,11.05-7.82,0-2.79-1.92-5.15-5.75-6.92-1.32-.59-4.42-1.77-9.58-3.69s-8.54-3.38-10.31-4.27C538.51,105.43,534,98.5,534,89.07a21.72,21.72,0,0,1,8.25-17.39C547.65,67.11,555,64.9,564,64.9c11.35,0,20.78,3.84,28.15,11.5L581.84,89.81c-5.6-5.31-11.79-8-18.42-8-5.45,0-9.14,3.1-9.14,6.78,0,3.39,2.36,5.75,7.08,7.37,10.61,4,17.09,6.63,19.3,7.81,9,4.71,13.56,11.49,13.56,20.34a23,23,0,0,1-8.84,18.56c-5.75,4.87-13.56,7.22-23.14,7.22Q541.24,149.93,529.08,134.46Z'
				/>
				<path
					className='diagnoson-cls-3'
					d='M615.3,77.72q12.15-12.37,30.94-12.37t31,12.37c8.11,8.11,12.08,18.13,12.08,29.92s-4,22-12.08,30.06-18.42,12.23-31,12.23-22.84-4.12-30.94-12.23-12.09-18.12-12.09-30.06S607.19,85.83,615.3,77.72Zm30.94,52.91A20.92,20.92,0,0,0,662,124a23.21,23.21,0,0,0,6.49-16.36A22.6,22.6,0,0,0,662,91.43a20.92,20.92,0,0,0-15.77-6.63,21.24,21.24,0,0,0-15.91,6.63A22.43,22.43,0,0,0,624,107.64,23,23,0,0,0,630.33,124,21.24,21.24,0,0,0,646.24,130.63Z'
				/>
				<path
					className='diagnoson-cls-5'
					d='M703.13,147.87V67.56h20.34v11.2c4.27-7.81,13.85-13.41,25.2-13.41A31.27,31.27,0,0,1,770.92,74c6,5.75,9.14,14,9.14,24.76v49.07H759.28V104.4c0-12.82-6.78-19.9-16.95-19.9-11.05,0-18.42,8.4-18.42,20.34v43Z'
				/>
				<path
					className='diagnoson-cls-7'
					d='M1074.38,86.66c-16.71-27.07-43-52.53-72.07-69.84l-.93-.56-.28-.09h0A126.06,126.06,0,0,0,940,0a111.29,111.29,0,0,0-39.63,7.13,102.94,102.94,0,0,0-34.48,21.63c-.8.73-1.49,1.39-2.11,2a105.94,105.94,0,0,0-31,70.9h-.09v7.85l.06.57h0a105.94,105.94,0,0,0,31,70.9c.62.61,1.31,1.28,2.09,2a102.94,102.94,0,0,0,34.5,21.64A111.29,111.29,0,0,0,940,211.76a126.09,126.09,0,0,0,61.08-16.17l.11,0,1.1-.67c29.09-17.31,55.36-42.76,72.07-69.83a35.69,35.69,0,0,0,0-38.45'
				/>
				<path
					className='diagnoson-cls-8'
					d='M1071.21,88.61c-17.58-28.49-44.34-52.84-70.8-68.6h-.06c-43.46-24.73-97-21.63-131.91,11.48-.69.61-1.35,1.25-2,1.91a102.19,102.19,0,0,0-30,72h0v1h0a102.24,102.24,0,0,0,30,72c.66.65,1.32,1.29,2,1.9,34.91,33.11,88.45,36.22,131.91,11.47h.06c26.46-15.74,53.22-40.1,70.8-68.59a31.95,31.95,0,0,0,0-34.54'
				/>
				<path
					className='diagnoson-cls-7'
					d='M973.19,79.13a22.06,22.06,0,0,0-5.78-9.91,24.58,24.58,0,0,0-10.51-5.93A55.23,55.23,0,0,0,941.12,61a69.29,69.29,0,0,0-12.76,1.19,52.23,52.23,0,0,0-10.14,3.06,29.26,29.26,0,0,0-7.88,4.38c-1.8,1.48-2.67,3-2.67,4.57a12.5,12.5,0,0,0,1.35,5,13.36,13.36,0,0,0,2,3.26c1.17,1.44,3.87,2,6.2.84,3.16-1.51,3.47-1.63,6.74-2.94A37.59,37.59,0,0,1,937.5,78q7.92,0,11.47,2.79c2.35,1.85,3.54,5,3.54,9.42v3c-1.16,0-2.26,0-3.71.05s-2.67,0-4.07.15a123.4,123.4,0,0,0-13.34,1.36,48.48,48.48,0,0,0-14.09,4.53,31.55,31.55,0,0,0-10.58,8.81q-4.23,5.51-4.23,14.27a26.83,26.83,0,0,0,2.42,11.94,21.72,21.72,0,0,0,6.2,7.82,24.76,24.76,0,0,0,8.45,4.22,35.23,35.23,0,0,0,9.32,1.27,37.39,37.39,0,0,0,15.17-2.75,31.39,31.39,0,0,0,10-6.72,11.19,11.19,0,0,0,4.16,7c2.13,1.61,5.15,2.41,9.28,2.41,4.72,0,7.42-2.88,7.42-7.3V93a50,50,0,0,0-1.73-13.88m-20.34,44.18h0a4.37,4.37,0,0,1-1.29,2.74A13.22,13.22,0,0,1,948,128.8a21.86,21.86,0,0,1-5.17,2,23.28,23.28,0,0,1-6.21.81c-4.37,0-7.53-1-9.36-3.08a10.72,10.72,0,0,1-2.72-7.45,9.75,9.75,0,0,1,2.08-6.44,15.22,15.22,0,0,1,5.34-4.08,30.15,30.15,0,0,1,7.63-2.32,66.91,66.91,0,0,1,8.21-.88c1.08-.06,1.84,0,2.64-.06s1.63,0,2.39,0Z'
				/>
				<path
					className='diagnoson-cls-7'
					d='M1056.27,105.89a36.1,36.1,0,0,0-.31-4.69h0a5.78,5.78,0,0,0-5.75-5.16h-15a4.75,4.75,0,0,1-4.76-4.76v-15a5.77,5.77,0,0,0-5.16-5.74h0a35.56,35.56,0,0,0-9.37,0h0a5.78,5.78,0,0,0-5.17,5.74v15a4.74,4.74,0,0,1-4.75,4.76h-15a5.78,5.78,0,0,0-5.74,5.16h0a35.48,35.48,0,0,0,0,9.36h0a5.78,5.78,0,0,0,5.74,5.16h15a4.75,4.75,0,0,1,4.75,4.76v15a5.77,5.77,0,0,0,5.17,5.74h0a35.56,35.56,0,0,0,9.37,0h0a5.76,5.76,0,0,0,5.16-5.74v-15a4.76,4.76,0,0,1,4.76-4.76h15a5.78,5.78,0,0,0,5.75-5.16h0a36,36,0,0,0,.31-4.67'
				/>
			</g>
		</g>
	</svg>
)

export default SvgDiagnosonPositivo
