import React, { useState } from 'react'
import styled from 'styled-components'
import { FormGroup } from '../Blocks'

export const Label = styled.label`
	white-space: pre;
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	line-height: ${props => (props.lineHeight ? props.lineHeight : '60px')};
	background: transparent;
	margin: 0px auto;
	cursor: text;
	transition: all 0.15s ease-in-out;
	height: 21px;
	color: ${props => (props.color ? props.color : '#a2a2a2')};
	font-size: ${props => (props.fontSize ? props.fontSize : '16px')};
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	text-align: left;

	@media (max-width: 1024px) {
		font-size: 12px;
	}

	@media (max-width: 768px) {
		font-size: 16px;
	}

	${props =>
		props.valueInput
			? `	
			margin-top: ${props => (props.marginTop ? props.marginTop : '')};
			height: 20px;
			line-height: 20px;
		
			`
			: ''}
`

const Input = styled.input`
	font-size: ${props => (props.fontSize ? props.fontSize : '12px')};
	color: ${props => (props.color ? props.color : '')};
	height: 24px;
	border: none;
	border-bottom: ${({ borderBottom, error }) => (error && '1px solid #ff0000') || borderBottom};;
	background: transparent;
	font-family: 'fs_lolaregular' !important;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: left;
	position: relative;
	display: block;
	width: 100%;
	margin: ${({ iconLeft }) => (iconLeft ? '21px auto auto -21px' : '21px auto auto auto')};
	background: ${props =>
		props.inSearch
			? ''
			: `linear-gradient(to top, ${props.theme.colors.primary} 50%, ${props.theme.colors.primary} 50%)`};
	background-position: ${({ inSearch }) => (inSearch ? '' : 'left bottom')};
	background-size: ${({ inSearch }) => (inSearch ? '' : '0 1px')};
	background-repeat: ${({ inSearch }) => (inSearch ? '' : 'no-repeat')};
	padding: ${({ padding }) => (padding ? padding : '0')};
	transition: all 0.2s ease-in-out;

	padding-inline-end: ${({ paddingInlineEnd, iconLeft }) => paddingInlineEnd};
	padding-inline-start: ${({ iconLeft }) => (iconLeft ? '15px' : '')};

	&:focus {
		background-position: left bottom;
		background-size: 100% 1px;
	}

	/* &:not([value=''])+${Label},  */
	&:required:valid+${Label}, 
	&:focus+${Label} {
		margin-top: ${props => (props.marginTop ? props.marginTop : '')};
		height: 20px;
		line-height: 20px;
		font-size: 11px;
	}

	&:focus &::-webkit-input-placeholder {
		color: transparent;
		font-size: 0;
	}

	&:focus &:-moz-placeholder {
		color: transparent;
		font-size: 0;
	}

	&:focus &::-moz-placeholder {
		color: transparent;
		font-size: 0;
	}

	&:focus &:-ms-input-placeholder {
		color: transparent;
		font-size: 0;
	}

	outline: none;
	width: 100%;

	@media (max-width: 767.98px) {
		background:  ${props => (props.background ? props.background : 'transparent')};
		font-size: 14px;
	}
	
	@media (max-width: 375px) {
		// padding: 0;
	}



;
`

/**
 * Componente de busca
 * @param {(event)=>{}} onSearch - callback para executar ao digitar
 * @param {()=>{}} onKeyPress - callback para executar ao digitar Insert
 * @param {string | React.ReactNode | React.ReactElement} iconLeft - item a ser exibido à esqueda do input
 * @param {any} ...props - quaisquer outras props seram colocadas para o elemento de input
 */
const Required = styled.span`
	color: ${props => props.theme.colors.primary};
`

export default ({
	label,
	id,
	name,
	lineHeight,
	fontSize,
	color,
	required,
	inputFontSize,
	inputColor,
	inputPadding,
	inputBorderBottom,
	inputMarginTop,
	inputBackground,
	inputType,
	...props
}) => {
	const [valueInput, setValueInput] = useState('')

	return (
		<FormGroup>
			<Input
				type={inputType}
				id={id}
				className='input-float'
				name={name}
				fontSize={inputFontSize}
				color={inputColor}
				padding={inputPadding}
				borderBottom={inputBorderBottom}
				marginTop={inputMarginTop}
				background={inputBackground}
				maxLength={props.maxLength}
				{...props}
				onChange={e => {
					setValueInput(e.target.value)
					props.onChange(e)
				}}
				{...(required ? { required } : {})}
			/>
			{label && (
				<Label
					htmlFor={id || name}
					className='label-float'
					lineHeight={lineHeight}
					fontSize={fontSize}
					color={color}
					valueInput={valueInput}
				>
					{label}
					{required === true ? <Required>*</Required> : null}
				</Label>
			)}
		</FormGroup>
	)
}
