import React from 'react'
import theme from '../../theme'

const SvgOQueFazemosInline = props => (
	<svg
		width={25}
		height={25}
		viewBox='0 0 52.089 50'
		preserveAspectRatio='xMidYMid meet'
		style={{ maxWidth: '100%', height: 'auto' }}
		{...props}
	>
		<defs>
			<style>{`.o_que_fazemos_inline_svg__a{fill:${theme.colors.primary}}`}</style>
		</defs>
		<path
			className='o_que_fazemos_inline_svg__a'
			d='M0 0v8.824h15.441V0zm13.971 7.353h-.735V2.941h-1.471v4.412h-1.471V5.882H8.823v1.471H6.617V5.882H5.146v1.471H3.675V2.941H2.204v4.412h-.735V1.471h12.5zM1.654 10.294v1.471h.551v32.717a5.515 5.515 0 0011.029 0v-32.72h.735v-1.471zm2.022 25.551h3.86v-1.471H3.672v-1.471h3.86v-1.471h-3.86v-1.471h3.86v-1.469h-3.86v-1.47h3.86v-1.471h-3.86v-5.882h5.331V37.5H3.672v-1.654zm8.088 8.64a4.044 4.044 0 11-8.088 0V38.97h6.8V18.198h-6.8v-6.434h8.088'
		/>
		<path
			className='o_que_fazemos_inline_svg__a'
			d='M6.066 21.139h1.471v1.471H6.066zM18.324 0v8.824h15.441V0zm13.971 7.353h-.735V2.941h-1.471v4.412h-1.471V5.882h-1.471v1.471h-2.206V5.882H23.47v1.471h-1.471V2.941h-1.471v4.412h-.735V1.471h12.5zM19.978 10.294v1.471h.551v32.717a5.515 5.515 0 0011.03 0v-32.72h.735v-1.471zM22 35.845h3.86v-1.471H22v-1.471h3.86v-1.471H22v-1.471h3.86v-1.469H22v-1.47h3.86v-1.471H22v-5.882h5.331V37.5H22v-1.654zm8.088 8.64a4.044 4.044 0 11-8.088 0V38.97h6.8V18.198H22v-6.434h8.088'
		/>
		<path className='o_que_fazemos_inline_svg__a' d='M24.39 21.139h1.471v1.471H24.39z' />
		<g>
			<path
				className='o_que_fazemos_inline_svg__a'
				d='M36.648 0v8.824h15.441V0zm13.971 7.353h-.735V2.941h-1.471v4.412h-1.471V5.882h-1.471v1.471h-2.206V5.882h-1.471v1.471h-1.471V2.941h-1.471v4.412h-.735V1.471h12.5zM38.302 10.294v1.471h.551v32.717a5.515 5.515 0 0011.03 0v-32.72h.735v-1.471zm2.022 25.551h3.86v-1.471h-3.86v-1.471h3.86v-1.471h-3.86v-1.471h3.86v-1.469h-3.86v-1.47h3.86v-1.471h-3.86v-5.882h5.331V37.5h-5.331v-1.654zm8.088 8.64a4.044 4.044 0 01-8.088 0V38.97h6.8V18.198h-6.8v-6.434h8.088'
			/>
			<path className='o_que_fazemos_inline_svg__a' d='M42.714 21.139h1.471v1.471h-1.471z' />
		</g>
	</svg>
)

export default SvgOQueFazemosInline
