import { css } from 'styled-components'
import { prop, ifProp, switchProp } from 'styled-tools'

export default css`
	transition: 0.8s;
	opacity: ${switchProp(prop('state', 'exited'), {
		entering: '1',
		entered: '1',
		exiting: '0',
		exited: '0'
	})};

	/* isso ta bugando a animação n sei pq, quando o state é diff de none e flex */
	/* display: ${ifProp({ state: 'exited' }, 'none', 'flex')}; */
`
