import React from 'react'

const LogoFooter = props => (
	<svg width={315} height={51} {...props}>
		<defs>
			<clipPath id='prefix__b__logofooter'>
				<path
					data-name='Caminho 5219'
					d='M405.151 2c-.213 5.937-10.51 9.316-14.635 11.531-2.528 1.356-5.245 2.9-6.97 5.265-1.8 2.469-1.8 5.41-1.8 8.344a30.388 30.388 0 00.186 4.065 9.137 9.137 0 01.836-4.056 3.72 3.72 0 01.172-.378 9.064 9.064 0 011.149-1.612 16.226 16.226 0 013.261-2.759 87.343 87.343 0 018.446-4.546c5.5-2.868 9.423-5.794 9.658-12.318V2z'
					transform='translate(-381.745 -2)'
					fill='none'
				/>
			</clipPath>
			<clipPath id='prefix__c__logofooter'>
				<path
					data-name='Caminho 5221'
					d='M385.061 74.1a9.507 9.507 0 00-.028-.052z'
					transform='translate(-385.033 -74.047)'
					fill='none'
				/>
			</clipPath>
			<clipPath id='prefix__e__logofooter'>
				<path
					data-name='Caminho 5223'
					d='M405.123 52.05c-.2 5.653-9.746 8.992-13.717 11.052-5.5 2.856-9.427 5.809-9.66 12.323v3.54h.3c.2-5.653 9.746-8.992 13.716-11.052 5.5-2.855 9.427-5.809 9.66-12.323v-3.54z'
					transform='translate(-381.746 -52.05)'
					fill='none'
				/>
			</clipPath>
			<clipPath id='prefix__a__logofooter'>
				<path d='M0 0h315v51H0z' />
			</clipPath>
			<linearGradient
				id='prefix__d__logofooter'
				x1={-647.146}
				y1={70.763}
				x2={-645.465}
				y2={70.763}
				gradientUnits='objectBoundingBox'
			>
				<stop offset={0} stopColor='#890005' />
				<stop offset={1} stopColor='#da001c' />
			</linearGradient>
		</defs>
		<g data-name='Prancheta \u2013 1'>
			<g data-name='Grupo 6264' clipPath='url(#prefix__a__logofooter)'>
				<path
					data-name='Caminho 5218'
					d='M136.555 27.23a6.884 6.884 0 00-.185 1.485 3.955 3.955 0 00.029.49 9.185 9.185 0 001.617 4.287c1.732 2.373 4.437 3.9 6.972 5.266 4.131 2.233 14.417 5.592 14.631 11.533h.3v-3.539c-.229-6.372-3.951-9.326-9.35-12.158-2.832-1.486-5.8-2.772-8.484-4.526a17.129 17.129 0 01-3.341-2.722 9.429 9.429 0 01-1.343-1.83 3.678 3.678 0 01-.168-.367 12.317 12.317 0 00-.68 2.081'
					fill='#fff'
				/>
				<g data-name='Grupo 6265'>
					<g data-name='Grupo 6264' clipPath='url(#prefix__b__logofooter)' transform='translate(136.214)'>
						<path data-name='Ret\xE2ngulo 2195' fill='#fff' d='M-.003 0h23.711v29.205H-.003z' />
					</g>
				</g>
				<path
					data-name='Caminho 5220'
					d='M150.109 15.589c-.791.412-1.607.818-2.4 1.212a53.489 53.489 0 00-6.073 3.351 16.382 16.382 0 00-3.32 2.808 9.221 9.221 0 00-1.187 1.666 3.42 3.42 0 00-.186.407 6.642 6.642 0 00-.64 2.718 10.627 10.627 0 00.116 1.541 9.017 9.017 0 001.6 4.2 13.222 13.222 0 003.109 2.954 12.289 12.289 0 01-2.929-2.893 9.322 9.322 0 01-1.592-4.2 7.823 7.823 0 01-.066-1.371 7.545 7.545 0 01.675-2.832 3.467 3.467 0 01.171-.377 8.936 8.936 0 011.15-1.612 16.074 16.074 0 013.266-2.761 53.538 53.538 0 016.044-3.334c.79-.392 1.608-.8 2.4-1.212 5.328-2.778 8.682-5.482 9.483-10.479-.855 4.844-4.4 7.488-9.623 10.211M159.903 0l.007 3.539a15.078 15.078 0 01-.175 1.84 13.4 13.4 0 00.19-1.845L159.914 0z'
					fill='none'
				/>
				<g data-name='Grupo 6267'>
					<g data-name='Grupo 6266' clipPath='url(#prefix__c)' transform='translate(137.375 25.464)'>
						<path data-name='Ret\xE2ngulo 2196' fill='url(#prefix__d)' d='M0 0h.21v.361H0z' />
					</g>
				</g>
				<path
					data-name='Caminho 5222'
					d='M138.867 27.582a16.943 16.943 0 003.391 2.742 52.321 52.321 0 005.561 3.217l.035.018c.849.421 1.728.856 2.576 1.3 5.656 2.967 8.972 5.866 9.189 11.9l-.007 3.383v.151h.31v-.151h-.007l.007-3.386c-.223-6.183-3.6-9.14-9.35-12.158-.853-.447-1.732-.883-2.581-1.306l-.042-.021a56.618 56.618 0 01-5.861-3.2 16.746 16.746 0 01-3.341-2.722 9.765 9.765 0 01-1.161-1.522 11.192 11.192 0 001.282 1.758'
					fill='#fff'
				/>
				<g data-name='Grupo 6269'>
					<g data-name='Grupo 6268' clipPath='url(#prefix__e__logofooter)' transform='translate(136.214 17.689)'>
						<path data-name='Ret\xE2ngulo 2197' fill='#fff' d='M0 0h23.679v26.915H0z' />
					</g>
				</g>
				<path
					data-name='Caminho 5224'
					d='M150.093 33.283c-.641.333-1.451.71-2.333 1.119-4.472 2.077-11.23 5.216-11.54 10.051v.151h.3v-.141c.3-4.646 6.958-7.739 11.388-9.8.861-.4 1.675-.779 2.321-1.114 5.531-2.869 8.934-5.668 9.565-11.056-.669 5.233-4.279 7.972-9.7 10.787m9.793-15.594l.007 3.539q-.024.657-.1 1.267c.052-.409.087-.832.1-1.273l-.007-3.534z'
					fill='#fff'
				/>
				<path
					data-name='Caminho 5225'
					d='M185.749 19.748l-.479.94a11.974 11.974 0 00-4.62-1.059 7.526 7.526 0 00-7.652 7.46 7.337 7.337 0 007.436 7.455 7.765 7.765 0 005.246-1.972v-4.208h-4.765v-1.059h5.893v5.7a8.7 8.7 0 01-6.374 2.694 8.613 8.613 0 11.1-17.224 12.467 12.467 0 015.218 1.271'
					fill='#fff'
				/>
				<path
					data-name='Caminho 5226'
					d='M191.378 23.021l.216 2.359a4.024 4.024 0 013.994-2.382v1.274c-2.069 0-3.922 1.084-3.922 3.706v7.531h-1.2V23.021z'
					fill='#fff'
				/>
				<path
					data-name='Caminho 5227'
					d='M199.631 22.998v7.337c0 2.55 1.059 4.209 4.089 4.209a4.124 4.124 0 004.067-4.088v-7.458h1.2v12.511h-.914l-.192-2.431a4.638 4.638 0 01-4.354 2.623c-3.467 0-5.1-1.9-5.1-5.2v-7.503z'
					fill='#fff'
				/>
				<path
					data-name='Caminho 5228'
					d='M225.327 29.254a6.169 6.169 0 01-6.043 6.447 5.146 5.146 0 01-4.907-3v6.9h-1.2v-16.6h.941l.194 2.911a5.354 5.354 0 014.977-3.1 6.157 6.157 0 016.043 6.45m-11.07 0a5.058 5.058 0 004.909 5.363 5.387 5.387 0 10-4.909-5.363'
					fill='#fff'
				/>
				<path
					data-name='Caminho 5229'
					d='M234.293 22.804a6.449 6.449 0 11-6.374 6.45 6.384 6.384 0 016.374-6.45m-5.174 6.45a5.2 5.2 0 105.174-5.372 5.267 5.267 0 00-5.174 5.368'
					fill='#fff'
				/>
				<path
					data-name='Caminho 5230'
					d='M255.831 18.667v3.128h-8.566v4.138h7.292v3.032h-7.292v6.544h-3.369V18.667z'
					fill='#fff'
				/>
				<path
					data-name='Caminho 5231'
					d='M261.24 17.897v14.22a.384.384 0 00.436.433c.339 0 .65-.023 1.155-.071v2.619a8.11 8.11 0 01-2.408.412 2.32 2.32 0 01-2.478-2.55V17.897z'
					fill='#fff'
				/>
				<path
					data-name='Caminho 5232'
					d='M270.505 22.757c3.874 0 5.749 3.006 5.749 6.327a8.194 8.194 0 01-.119 1.443h-8.4c.147 1.782 1.686 2.454 3.179 2.454a9.084 9.084 0 003.536-.745l1.1 2.43a9.915 9.915 0 01-4.714 1.082 6.182 6.182 0 01-6.544-6.376c0-3.85 2.5-6.616 6.207-6.616m2.62 5.461c-.07-1.371-.6-2.573-2.43-2.573a2.889 2.889 0 00-3.008 2.573z'
					fill='#fff'
				/>
				<path
					data-name='Caminho 5233'
					d='M281.713 22.998v7.337c0 1.47.747 2.237 2.359 2.237a2.13 2.13 0 002.405-2.188v-7.386h3.367v12.511h-2.574l-.41-1.421a4.194 4.194 0 01-3.559 1.661c-3.247 0-4.9-1.9-4.9-5.246v-7.505z'
					fill='#fff'
				/>
				<path
					data-name='Caminho 5234'
					d='M295.474 23.119l.433 1.973c.769-1.588 2.07-2.093 4.284-2.093v3.415h-.41c-2.12 0-3.635.819-3.635 3.515v5.582h-3.318V23.119z'
					fill='#fff'
				/>
				<path
					data-name='Caminho 5235'
					d='M304.182 22.998l3.081 9.023h.073l3.174-9.023H314l-5.366 13.305c-.936 2.31-1.853 3.3-3.9 3.3a9.022 9.022 0 01-2.7-.409l.58-3.006a12.923 12.923 0 002.018.217c.507 0 .744-.482.917-.962l-4.863-12.44z'
					fill='#fff'
				/>
				<path
					data-name='Caminho 5236'
					d='M6.34 25.026c-1.816 0-2.834 1.358-2.834 4.513s1.018 4.532 2.834 4.532 2.834-1.372 2.834-4.532-1.018-4.513-2.834-4.513m.36 10.093a1.831 1.831 0 00-.159.755 1.006 1.006 0 00.26.7 1.206 1.206 0 00.864.291A4.077 4.077 0 009.8 36.03l-.088 1.01a3.889 3.889 0 01-2.384.809 2.2 2.2 0 01-1.575-.532 1.584 1.584 0 01-.469-1.2 2.163 2.163 0 01.28-1.031C3.281 34.724 2 32.732 2 29.539c0-3.533 1.575-5.576 4.34-5.576 2.749 0 4.34 2.044 4.34 5.577 0 3.382-1.451 5.407-3.975 5.58'
					fill='#fff'
				/>
				<path
					data-name='Caminho 5237'
					d='M22.663 34.81a1.762 1.762 0 01-.933.239 1.1 1.1 0 01-1.21-.96 3.087 3.087 0 01-2.487 1.045 3.12 3.12 0 01-2.337-.875 3.222 3.222 0 01-.637-2.266v-5.336h1.33v5.185a2.275 2.275 0 00.431 1.731 1.883 1.883 0 001.454.516 2.056 2.056 0 001.624-.634 2.761 2.761 0 00.518-1.992v-4.806h1.33v6.713c0 .549.121.738.467.738a1.083 1.083 0 00.5-.157z'
					fill='#fff'
				/>
				<path
					data-name='Caminho 5238'
					d='M31.59 30.725a7.334 7.334 0 00-1.643-.21c-1.591 0-2.143.771-2.143 1.819a1.7 1.7 0 001.778 1.854 1.93 1.93 0 001.558-.62 2.507 2.507 0 00.45-1.594zm2.247 4.085a1.759 1.759 0 01-.916.239 1.07 1.07 0 01-1.229-.96 2.694 2.694 0 01-2.28 1.048 2.629 2.629 0 01-2.924-2.73c0-1.682 1.108-2.815 3.319-2.815a7.633 7.633 0 011.783.222v-.445a1.913 1.913 0 00-.4-1.355 2.068 2.068 0 00-1.608-.568 5.471 5.471 0 00-2.348.549l-.019-.977a6.343 6.343 0 012.507-.516 3.355 3.355 0 012.592.875 2.776 2.776 0 01.606 1.972v4.035c0 .5.085.722.447.722a1.073 1.073 0 00.518-.157z'
					fill='#fff'
				/>
				<path
					data-name='Caminho 5239'
					d='M40.361 34.81a1.762 1.762 0 01-.933.239 1.281 1.281 0 01-1-.376 1.6 1.6 0 01-.291-1.081v-9.371h-.971v-.979h2.3v10.14c0 .549.1.722.45.722a1.213 1.213 0 00.518-.157z'
					fill='#fff'
				/>
				<path
					data-name='Caminho 5240'
					d='M45.595 25.165a.841.841 0 010-1.682.841.841 0 110 1.682m1.676 9.645a1.781 1.781 0 01-.936.239 1.27 1.27 0 01-1-.376 1.586 1.586 0 01-.294-1.081v-5.951h-.968v-.979h2.3v6.729c0 .549.1.722.45.722a1.224 1.224 0 00.516-.157z'
					fill='#fff'
				/>
				<path
					data-name='Caminho 5241'
					d='M56.787 27.689a4.364 4.364 0 00-1.4-.242c-1.9 0-2.974 1.185-2.974 3.641 0 1.766.694 3.07 2.282 3.07a1.886 1.886 0 001.8-1.062 3.5 3.5 0 00.3-1.58zm2.23 7.121a1.781 1.781 0 01-.936.239 1.084 1.084 0 01-1.226-.941 2.708 2.708 0 01-2.282 1.028c-2.4 0-3.509-1.734-3.509-3.981a5.036 5.036 0 011.191-3.519 4.1 4.1 0 013.029-1.133 4.672 4.672 0 011.5.241v-2.523h-.968v-.979h2.3v10.161c0 .532.1.705.45.705a1.211 1.211 0 00.516-.157z'
					fill='#fff'
				/>
				<path
					data-name='Caminho 5242'
					d='M67.928 30.725a7.311 7.311 0 00-1.645-.21c-1.591 0-2.143.771-2.143 1.819a1.7 1.7 0 001.778 1.854 1.93 1.93 0 001.558-.62 2.507 2.507 0 00.45-1.594zm2.247 4.085a1.759 1.759 0 01-.916.239c-.71 0-1.141-.307-1.226-.96a2.7 2.7 0 01-2.282 1.048 2.629 2.629 0 01-2.924-2.73c0-1.682 1.108-2.815 3.319-2.815a7.633 7.633 0 011.783.222v-.445a1.913 1.913 0 00-.4-1.355 2.068 2.068 0 00-1.608-.568 5.471 5.471 0 00-2.348.549l-.019-.977a6.343 6.343 0 012.507-.516 3.355 3.355 0 012.592.875 2.776 2.776 0 01.606 1.972v4.035c0 .5.085.722.447.722a1.091 1.091 0 00.521-.157z'
					fill='#fff'
				/>
				<path
					data-name='Caminho 5243'
					d='M79.444 27.689a4.336 4.336 0 00-1.4-.242c-1.9 0-2.974 1.185-2.974 3.641 0 1.766.694 3.07 2.282 3.07a1.886 1.886 0 001.8-1.062 3.5 3.5 0 00.294-1.58zm2.233 7.121a1.781 1.781 0 01-.936.239 1.084 1.084 0 01-1.226-.941 2.708 2.708 0 01-2.282 1.028c-2.4 0-3.509-1.734-3.509-3.981a5.036 5.036 0 011.191-3.519 4.1 4.1 0 013.029-1.133 4.644 4.644 0 011.5.241v-2.523h-.966v-.979h2.3v10.161c0 .532.1.705.45.705a1.224 1.224 0 00.516-.157z'
					fill='#fff'
				/>
				<path
					data-name='Caminho 5244'
					d='M88.872 27.448c-1.314 0-2.126.993-2.23 2.916h4.184c.1 0 .14-.069.14-.154 0-1.613-.675-2.762-2.093-2.762m2.543 3.827h-4.773c.1 1.819 1.04 2.864 2.661 2.864a5.012 5.012 0 002.615-.787l-.055 1.045a5.178 5.178 0 01-2.73.738c-2.56 0-3.838-1.7-3.838-4.271 0-2.8 1.435-4.359 3.629-4.359 2.022 0 3.319 1.509 3.319 3.912 0 .6-.206.859-.828.859'
					fill='#fff'
				/>
				<path
					data-name='Caminho 5245'
					d='M107.992 27.689a4.336 4.336 0 00-1.4-.242c-1.9 0-2.974 1.185-2.974 3.641 0 1.766.694 3.07 2.282 3.07a1.886 1.886 0 001.8-1.062 3.5 3.5 0 00.294-1.58zm2.233 7.121a1.781 1.781 0 01-.936.239 1.084 1.084 0 01-1.226-.941 2.708 2.708 0 01-2.282 1.028c-2.4 0-3.509-1.734-3.509-3.981a5.035 5.035 0 011.191-3.519 4.1 4.1 0 013.029-1.133 4.644 4.644 0 011.5.241v-2.523h-.966v-.979h2.3v10.161c0 .532.1.705.45.705a1.224 1.224 0 00.516-.157z'
					fill='#fff'
				/>
				<path
					data-name='Caminho 5246'
					d='M117.525 27.465c-1.451 0-2.337 1.045-2.337 3.363s.886 3.347 2.337 3.347 2.334-1.032 2.334-3.347-.883-3.363-2.334-3.363m0 7.67c-2.266 0-3.684-1.544-3.684-4.307s1.418-4.323 3.684-4.323 3.682 1.561 3.682 4.323-1.418 4.307-3.682 4.307'
					fill='#fff'
				/>
			</g>
		</g>
	</svg>
)

export default LogoFooter
