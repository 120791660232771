import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'

const Wrapper = styled.div`
	margin: ${({ marginless }) => (marginless ? 'auto' : '20px auto')};
	max-width: ${theme('container')};
	padding: 0 15px;

	@media (max-width: 1024px) {
		padding: ${({ paddingZeroMax1024 }) => (paddingZeroMax1024 ? '0' : '')};
	}

	@media (max-width: 768px) {
		padding: ${({ paddingZeroMax768 }) => (paddingZeroMax768 ? '0' : '0 10px')};
	}
`

export default function MainContainer({
	children,
	needPaddingZeroMax1024 = true,
	needPaddingZeroMax768 = false,
	...props
}) {
	return (
		<Wrapper paddingZeroMax1024={needPaddingZeroMax1024} paddingZeroMax768={needPaddingZeroMax768} {...props}>
			{children}
		</Wrapper>
	)
}
