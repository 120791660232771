import React from 'react'

import { useToolbarState, Toolbar } from 'reakit/Toolbar'
import styled from 'styled-components'

import SelectMobile from './SelectMobile'

import { Transition } from 'react-transition-group'
import '../hamburgers.css'
import MobileMenu from './MobileMenu'
import { theme } from 'styled-tools'
import { Link } from 'gatsby'

// TODO: 3 vez que escrevo isso, acho que da pra abstrair
const StyledToolbar = styled(Toolbar)`
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	font-family: '-apple-system', 'system-ui', 'BlinkMacSystemFont', 'Segoe UI', 'Helvetica Neue', Helvetica, Arial,
		sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	display: flex;
	flex-direction: row;
	&& {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		background-color: ${theme('colors.white')};
		padding: 10px;

		width: 100%;
		border-bottom: 1px solid ${theme('colors.gray8')};

		/* margin: 0 auto;
    width: fit-content; */
	}
`

const LogoWrapper = styled.div`
	height: 52px;
	width: 164px;
	/* margin-right: 20px; */

	@media (max-width: 991.98px) {
		width: 200px;

		//de alguma forma, os estilos gradientes do logo de diagnoson não funcionam legal, então, aplicamos esse estilo para ficar de uma cor sólida
		.diagnoson-cls-1,
		.diagnoson-cls-2,
		.diagnoson-cls-3,
		.diagnoson-cls-4,
		.diagnoson-cls-5,
		.diagnoson-cls-6 {
			fill: #1b5ca3;
		}
	}
`

// const Separator = styled.hr`
// 	border-color: #f8f8f8;
// 	height: calc(100% - 5px);
// 	margin: 0 20px;
// `

const RightSideFlex = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`

/**
 * Barra de navegação para Mobile.
 * concentra a prop Toolbar do Reakit.
 */
export default function Mobile({
	toggleType,
	type,
	showExtension,
	toggle,
	regionais,
	logo,
	theme,
	contatos,
	contatosAgendar,
	menuItens,
	isClient
}) {
	const toolbar = useToolbarState()

	const logoLinkTo = isClient ? '/' : '/medico/'

	return (
		<>
			<StyledToolbar {...toolbar} aria-label='Barra de navegação'>
				<Link to={logoLinkTo}>
					<LogoWrapper>{logo}</LogoWrapper>
				</Link>
				<RightSideFlex>
					<SelectMobile regionais={regionais} theme={theme} />
					{/* <Separator /> */}
					<button
						className={`hamburger hamburger--spring ${showExtension ? 'is-active' : ''}`}
						type='button'
						onClick={() => toggle()}
					>
						<span className='hamburger-box'>
							<span className='hamburger-inner'></span>
						</span>
					</button>
				</RightSideFlex>
			</StyledToolbar>

			<Transition in={showExtension} unmountOnExit mountOnEnter timeout={100}>
				{state => (
					<MobileMenu
						toolbar={toolbar}
						state={state}
						toggleMenu={toggle}
						toggleType={toggleType}
						type={type}
						regionais={regionais}
						contatos={contatos}
						contatosAgendar={contatosAgendar}
						menuItens={menuItens}
					/>
				)}
			</Transition>
		</>
	)
}
