import React, { useState } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import MobileTwoButtons from './MobileTwoButtons'
import MobileList from './MobileList'
import { Search } from '@monorepo-amais/commons/components/Inputs'
import animation from './animation.styled'
import { theme } from 'styled-tools'
import MobileClientOrDoctor from './MobileClientOrDoctor'
import sanitizeRegex from '@monorepo-amais/commons/utils/sanitizeRegex'

const Wrapper = styled.div`
	min-height: 50px;
	${animation}
	background-color: ${theme('colors.white')};
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`

const SearchWrapper = styled.div`
	width: 90%;
	margin: 0 auto;
	margin-top: 20px;
`

/**
 * Componente "Wrapper" do menu mobile. Recebe a prop toolbar do Reakit, state do RTG e ações para chavear o menu e tipo
 */
export default function MobileMenu({
	toolbar,
	state,
	toggleMenu,
	toggleType,
	type,
	regionais,
	contatos,
	contatosAgendar,
	menuItens,
	colorIcon,
	onSearch,
	...props
}) {
	const [inputSearch, setInputSearch] = useState('')

	const handleSearch = e => {
		e.preventDefault()
		if (inputSearch !== '') {
			navigate(`/busca?q=${inputSearch}`)
			setInputSearch('')
		}
	}
	return (
		<Wrapper state={state}>
			<SearchWrapper>
				<Search
					label='Busque em todo o site '
					value={inputSearch}
					onChange={e => setInputSearch(e.target.value.replace(sanitizeRegex, ''))}
					onSearch={handleSearch}
					onKeyPress={handleSearch}
					colorIcon='#8e8e93'
					backgroundSearch='#fff'
					borderSearch='solid 1px #e5e5e5'
					inputBorderBottom='none'
					paddingSearch='none'
				/>
			</SearchWrapper>
			<MobileClientOrDoctor toolbar={toolbar} toggleType={toggleType} type={type} />
			<MobileList toggleMenu={toggleMenu} menuItens={menuItens} />
		</Wrapper>
	)
}
